import { List } from '@chakra-ui/react';

import { AuthorizedUsersListItem } from './AuthorizedUsersListItem';

type AuthorizedUsersListProps = {
  users: string[];
  deleteHandler: (email: string) => void;
};

export function AuthorizedUsersList({
  users,
  deleteHandler,
}: AuthorizedUsersListProps) {
  return (
    <List>
      {users.map((user) => (
        <AuthorizedUsersListItem
          key={user}
          email={user}
          deleteHandler={deleteHandler}
        />
      ))}
    </List>
  );
}
