import {
  Button,
  HStack,
  Icon,
  Tooltip,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';

type Props = {
  name: string;
  icon: IconType;
  hasAnimation?: boolean;
  onClick: () => void;
  buttonId?: string;
};

export function SidebarButton({ name, icon, onClick, buttonId }: Props) {
  const [isExtraLarge] = useMediaQuery('(min-width: 80em)');

  return (
    <Tooltip
      w="full"
      h="full"
      label={name}
      hasArrow
      placement="right"
      isDisabled={isExtraLarge}
    >
      <Button
        id={buttonId}
        w="full"
        variant="ghost"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        onClick={onClick}
        color="brand.secondary"
        _hover={{ color: 'brand.primary' }}
        p={4}
        py={6}
        gap={6}
      >
        <HStack alignItems="center" justifyContent="start" gap={4}>
          <Icon as={icon} boxSize={4} />
          <Text
            fontSize="sm"
            fontWeight="normal"
            display={{ base: 'none', xl: 'block' }}
          >
            {name}
          </Text>
        </HStack>
      </Button>
    </Tooltip>
  );
}
