import { Flex, useMediaQuery } from '@chakra-ui/react';

type Props = {
  list: React.ReactNode;
  details: React.ReactNode;
  isDetailsClosed?: boolean;
};

export function PageContainer({ list, details, isDetailsClosed }: Props) {
  const [isMedium] = useMediaQuery('(max-width: 1024px)');

  return (
    <Flex w="full" h={'full'} p={0} flexDir="row" overflow="hidden">
      <Flex
        h="full"
        w={{ base: isDetailsClosed ? 'full' : 0, md: '70%', lg: 'full' }}
        transition="ease-in-out 0.5s"
        gap={4}
        flexDir="column"
        overflow="auto"
      >
        {list}
      </Flex>
      <Flex
        w={{ base: !isDetailsClosed ? 'full' : 0, md: '100%' }}
        transition="ease-in-out 0.4s"
        bgColor="brand.white"
        borderRadius={isMedium ? 'xl' : 'none'}
        borderLeftRadius="xl"
        overflow="hidden"
        m={!isDetailsClosed && isMedium ? 2 : 0}
      >
        {details}
      </Flex>
    </Flex>
  );
}
