import { Tabs } from '@chakra-ui/react';

type Props = {
  children: JSX.Element[];
  tabsChangeHandler?: (index: number) => void;
  tabIndex?: number;
};

export function CustomTabs({ children, tabsChangeHandler, tabIndex }: Props) {
  return (
    <Tabs
      p={{ base: 4, md: 2, lg: 6 }}
      isFitted
      h="full"
      w="full"
      display="flex"
      flexDir="column"
      gap={{ base: 3, md: 3, lg: 6 }}
      overflow="hidden"
      index={tabIndex}
      size={{ base: 'sm', md: 'md' }}
      onChange={tabsChangeHandler}
    >
      {children}
    </Tabs>
  );
}
