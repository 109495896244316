import { Text } from '@chakra-ui/react';

type Props = {
  text: string;
};

export function NoItemsPlaceholder({ text }: Props) {
  return (
    <Text size={{ sm: 'sm', md: 'md' }} my={4} textAlign="center">
      {text}
    </Text>
  );
}
