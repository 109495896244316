import { Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ChecklistProductsListItem } from './ChecklistProductsListItem';
import { ChecklistProductDto, DeleteChecklistProductDto } from '@models';

type Props = {
  products: ChecklistProductDto[];
  unlinkHandler(products: DeleteChecklistProductDto): void;
};

export function ChecklistProductsList({ products, unlinkHandler }: Props) {
  const { t } = useTranslation();

  return (
    <VStack h="full" alignItems="space-between" mb={5} overflow="auto" gap={0}>
      {products.map((product) => (
        <ChecklistProductsListItem
          key={product.id}
          product={product}
          unlinkHandler={unlinkHandler}
        />
      ))}

      {(!products || products.length === 0) && (
        <Text my={4} textAlign="center">
          {t('checklists:placeholder_no_products')}
        </Text>
      )}
    </VStack>
  );
}
