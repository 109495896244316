import { Box, Flex, Image, ListItem, Text } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';
import { ProductSearchResultDto, ProductSearchResultDtoKind } from '@models';
import { getProductImage } from '@utils';

type ProductsListItemProps = {
  productSearchResult: ProductSearchResultDto;
  onSelect: (product: ProductSearchResultDto) => void;
  selected: boolean;
};

export const ProductsListItem = ({
  productSearchResult,
  onSelect,
  selected,
}: ProductsListItemProps) => {
  const { t } = useTranslation();

  return (
    <ListItem
      borderRadius="md"
      flex={1}
      fontSize="sm"
      bg={selected ? 'brand.gradient' : 'brand.white'}
      onClick={() => onSelect(productSearchResult)}
      tabIndex={1}
      _hover={{
        cursor: 'pointer',
      }}
    >
      <Flex justifyContent="space-between" alignItems="center" gap={4} p={4}>
        <Box boxSize={{ base: 8, lg: 10 }}>
          <Image
            src={getProductImage(productSearchResult?.kind)}
            alt={t('products:image_alt_product_icon')}
          />
        </Box>
        <Flex
          direction="column"
          flex={1}
          lineHeight={{ base: 1.3, lg: 1.4 }}
          overflow="hidden"
          w="0"
        >
          <Text
            color={selected ? 'brand.white' : 'brand.secondary'}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            textTransform="capitalize"
            fontSize="xs"
          >
            {t(`products:kind_${productSearchResult.kind.toLowerCase()}`)}
          </Text>
          {productSearchResult.kind === ProductSearchResultDtoKind.CALENDAR ? (
            <Text
              pr={4}
              color={selected ? 'brand.white' : 'brand.primary'}
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {productSearchResult.name}
            </Text>
          ) : (
            <Text
              pr={4}
              color={selected ? 'brand.white' : 'brand.primary'}
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {productSearchResult.name}
            </Text>
          )}
        </Flex>
      </Flex>
    </ListItem>
  );
};
