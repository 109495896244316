import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';

import { notifyUser } from '../../common';

import { AddOrganizationalUnit } from './AddOrganizationalUnit';
import { OrganizationalUnitsList } from './OrganizationalUnitsList';
import { SettingsContent } from './SettingsContent';
import {
  useTenantControllerGetOrgUnits,
  useTenantControllerSetOrgUnits,
} from '@hooks';

export function OrganizationUnitsSettings() {
  const { t } = useTranslation();
  const [units, setUnits] = useState<string[]>([]);

  const {
    data: organizationalUnits,
    isLoading: isLoadingOrganizationalUnits,
    refetch,
  } = useTenantControllerGetOrgUnits();

  const { mutate: updateOrganizationalUnit } = useTenantControllerSetOrgUnits({
    mutation: {
      onSuccess: () =>
        notifyUser(
          t('settings:toast_success_update_organizational_units'),
          false,
        ),
      onError: () =>
        notifyUser(t('settings:toast_error_update_organizational_units'), true),
      onSettled: () => refetch(),
    },
  });

  const handleRemoveOrganizationalUnit = (unit: string) => {
    const filteredUnits = units.filter((path) => path !== unit);
    updateOrganizationalUnit({ data: { paths: filteredUnits } });
  };

  const handleUpdateOrganizationalUnit = (unit: string) => {
    if (!units) return;
    const newUnits = units.includes(unit) ? [...units] : [...units, unit];
    updateOrganizationalUnit({ data: { paths: newUnits } });
  };

  useEffect(() => {
    if (organizationalUnits?.data?.paths) {
      const orgPaths = organizationalUnits.data.paths;
      setUnits(orgPaths);
    }
  }, [organizationalUnits]);

  return (
    <SettingsContent
      title={t('settings:title_organizational_units')}
      description={t('settings:description_organizational_units')}
    >
      {!isLoadingOrganizationalUnits && units && (
        <OrganizationalUnitsList
          units={units}
          deleteHandler={handleRemoveOrganizationalUnit}
        />
      )}
      {!units && (
        <Text>{t('settings:placeholder_no_organizational_units')}</Text>
      )}
      <AddOrganizationalUnit addHandler={handleUpdateOrganizationalUnit} />
    </SettingsContent>
  );
}
