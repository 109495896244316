import '@fontsource-variable/montserrat';
import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  styles: {
    global: {
      ':host, :root': {
        '--chakra-ui-focus-ring-color': '#ff007d99',
      },
      'html, body, #root': {
        height: '100%',
        color: 'brand.primary',
        p: 0,
      },
      '::-webkit-scrollbar': { width: '6px' },
      '::-webkit-scrollbar-thumb': {
        bg: 'brand.secondary',
        borderRadius: 'md',
      },
      '::-webkit-scrollbar-track': { bg: 'brand.gray', borderRadius: 'md' },
    },
  },
  shadows: {
    outline: '0 0 0 3px var(--chakra-ui-focus-ring-color)',
  },
  breakpoints: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
  },
  fonts: {
    heading: `'Montserrat Variable', sans-serif`,
    body: `'Montserrat Variable', sans-serif`,
  },
  colors: {
    switch: {
      500: 'linear-gradient(225deg ,#D6006D 0%, #7A2563 70%, #2B445A 300%)',
    },
    brand: {
      primary: '#323E48',
      secondary: '#8D8D8D',
      gray: '#F1F1F1',
      white: '#FCFCFC',
      gradient:
        'linear-gradient(225deg ,#D6006D 0%, #7A2563 70%, #2B445A 300%)',
      'gradient-sync':
        'linear-gradient(225deg ,#D6006D 0%, #7A2563 70%, #2B445A 300%)',
      'gradient-error': 'linear-gradient(42deg, #B31217 0%, #E52D27 100%)',
      'gradient-success': 'linear-gradient(42deg, #56AB2F 0%, #A8E063 100%)',
      'gradient-info': 'linear-gradient(42deg, #0083B0 0%, #00B4DB 100%)',
      'gradient-warning': 'linear-gradient(42deg, #FC4A1A 0%, #F7B733 100%)',
      'gradient-install':
        'linear-gradient(225deg ,#D6006D 0%, #7A2563 70%, #2B445A 300%)',
    },
    radio: {
      500: 'linear-gradient(225deg ,#D6006D 0%, #7A2563 70%, #2B445A 300%)',
    },
  },
  components: {
    Select: {
      variants: {
        outline: {
          field: {
            _focus: {
              borderWidth: '2px',
              borderColor: 'var(--chakra-ui-focus-ring-color)',
              boxShadow: 'none',
            },
          },
        },
        solid: {
          field: {
            _focus: {
              borderWidth: '2px',
              borderColor: 'var(--chakra-ui-focus-ring-color)',
              boxShadow: 'none',
            },
          },
        },
      },
    },
    Input: {
      variants: {
        solid: {
          field: {
            bg: 'brand.gray',
            color: 'brand.primary',
            borderRadius: 'lg',
            borderColor: 'brand.gray',
            _hover: {
              bg: 'brand.gray',
              borderColor: 'brand.gray',
            },
            _active: {
              bg: 'brand.gray',
            },
            _invalid: {
              bg: 'red.100',
            },
            _focus: {
              borderWidth: '2px',
              borderColor: 'var(--chakra-ui-focus-ring-color)',
              boxShadow: 'none',
            },
          },
        },
        outline: {
          field: {
            _focus: {
              borderWidth: '2px',
              borderColor: 'var(--chakra-ui-focus-ring-color)',
              boxShadow: 'none',
            },
          },
        },
      },
    },
    Button: {
      variants: {
        solid: {
          bg: 'brand.gradient',
          color: 'brand.white',
          fontWeight: 'regular',
          _hover: {
            bg: 'brand.gradient',
            opacity: 0.9,

            _disabled: {
              bg: 'brand.inherit',
              opacity: 0.4,
            },
          },
          _active: {
            bg: 'brand.gradient',
          },
        },
        text: {
          bg: 'brand.gradient',
          bgClip: 'text',
        },
      },
    },
    Switch: {
      baseStyle: {
        track: {
          bg: 'brand.secondary',
          _checked: {
            bg: 'brand.gradient',
          },
        },
        thumb: {
          bg: 'brand.white',
          _checked: {
            bg: 'brand.white',
          },
        },
      },
    },
  },
});
