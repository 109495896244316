import {
  VStack,
  HStack,
  FormControl,
  Input,
  Button,
  Text,
  Icon,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdAdd } from 'react-icons/io';

type AddOrganizationalUnitProps = {
  addHandler: (unit: string) => void;
};

export function AddOrganizationalUnit({
  addHandler,
}: AddOrganizationalUnitProps) {
  const [unit, setUnit] = useState('');
  const { t } = useTranslation();

  function handleAddUnit() {
    addHandler(`/${unit}`);
    setUnit('');
  }

  return (
    <VStack w="full" alignItems="start" mt="auto" gap={{ base: 1, md: 2 }}>
      <Text ml={2} fontSize={{ base: 'sm', md: 'md' }}>
        {t('settings:label_add_organizational_unit')}
      </Text>
      <HStack w="full" alignItems="flex-start">
        <FormControl>
          <Input
            size="sm"
            bg="brand.gray"
            borderColor="brand.gray"
            id="unit"
            placeholder={t('settings:placeholder_organizational_unit')}
            borderRadius="md"
            pl="5"
            value={unit}
            color="brand.primary"
            onChange={(e) => setUnit(e.target.value)}
            _placeholder={{ color: 'brand.secondary' }}
            onKeyUp={(event) => {
              if (event.key !== 'Enter') return;
              handleAddUnit();
            }}
          />
        </FormControl>
        <Button
          id="add-org-unit"
          _hover={{ _disabled: { bg: 'inherit' } }}
          size="sm"
          border="none"
          onClick={handleAddUnit}
          alignSelf="flex-start"
          isDisabled={!unit}
        >
          <Icon as={IoMdAdd} />
        </Button>
      </HStack>
    </VStack>
  );
}
