import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink } from 'react-router-dom';
import { ChecklistProductDto, DeleteChecklistProductDto } from '@models';
import { getProductImage } from 'src/modules/common/utils/getProductImage';

type Props = {
  product: ChecklistProductDto;
  unlinkHandler(product: DeleteChecklistProductDto): void;
};

export function ChecklistProductsListItem({ product, unlinkHandler }: Props) {
  const { t } = useTranslation();
  const [displayIcon, setDisplayIcon] = useState('none');
  const { isOpen, onOpen: onOpenConfirmationModal, onClose } = useDisclosure();

  function handleUnlinkProduct() {
    unlinkHandler(product);
    onClose();
  }

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        px={3}
        py={3}
        css={css`
          cursor: default;
          &:hover .delete-icon {
            display: flex;
          }
        `}
      >
        <Flex justifyContent="start" alignItems="center" w="full" gap={3}>
          <Flex
            w={{ base: 12, lg: 14 }}
            h={{ base: 12, lg: 14 }}
            borderRadius="full"
            bg="brand.gray"
            justifyContent="center"
            alignItems="center"
            color="brand.white"
          >
            <Image
              boxSize={{ base: 5, lg: 6 }}
              src={getProductImage(product.kind)}
              alt={product.kind.toLocaleLowerCase()}
            />
            <Box
              position="absolute"
              justifyContent="center"
              alignItems="center"
              onClick={() => {
                onOpenConfirmationModal();
                setDisplayIcon('flex');
              }}
              _hover={{ cursor: 'pointer' }}
              className="delete-icon"
              display={displayIcon}
              borderRadius="full"
              bg="brand.gradient"
              opacity={0.9}
              w={{ base: 12, lg: 14 }}
              h={{ base: 12, lg: 14 }}
            >
              <CloseIcon
                mt="auto"
                position="absolute"
                color="brand.gray"
                boxSize="1rem"
              />
            </Box>
          </Flex>
          <Link
            mx={1}
            flex={1}
            borderRadius="md"
            as={ReactLink}
            to={`/products/${product.kind}/${product.id}`}
            _focusVisible={{
              boxShadow: 'none',
            }}
            _hover={{ textDecor: 'none' }}
            overflow="hidden"
            w="0"
          >
            <Flex direction="column">
              <Text
                fontSize="sm"
                color="brand.secondary"
                textTransform="lowercase"
              >
                {t(`products:kind_${product.kind.toLowerCase()}`)}
              </Text>
              <Text
                fontSize="sm"
                wordBreak="break-word"
                pr={6}
                color="brand.primary"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              >
                {product.name}
              </Text>
            </Flex>
          </Link>
        </Flex>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t('checklists:modal_header_unlink_product')}
          </ModalHeader>
          <ModalBody>
            <Text>
              {t('checklists:modal_body_unlink_product', {
                name: product.name,
              })}
            </Text>
          </ModalBody>
          <ModalFooter display="flex" gap={5}>
            <Button
              variant="text"
              onClick={() => {
                setDisplayIcon('none');
                onClose();
              }}
            >
              {t('globals:btn_cancel')}
            </Button>
            <Button
              id="unlink-product"
              variant="solid"
              onClick={handleUnlinkProduct}
            >
              {t('globals:btn_continue')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
