/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Workspace Warden
 * OpenAPI spec version: 0.0.1
 */

/**
 * @nullable
 */
export type TenantSubscriptionStatusDtoSubscriptionType =
  | (typeof TenantSubscriptionStatusDtoSubscriptionType)[keyof typeof TenantSubscriptionStatusDtoSubscriptionType]
  | null;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TenantSubscriptionStatusDtoSubscriptionType = {
  TRIAL: 'TRIAL',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
  CUSTOM: 'CUSTOM',
} as const;
