import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  changeImpersonatorHandler: () => void;
  impersonator: string;
  newImpersonator: string;
};

export function ImpersonatorModal({
  isOpen,
  onClose,
  changeImpersonatorHandler,
  impersonator,
  newImpersonator,
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('settings:modal_header_change_impersonator')}
        </ModalHeader>
        <ModalBody>
          <Text>
            {t('settings:modal_body_change_impersonator', {
              impersonator: impersonator,
              newImpersonator: newImpersonator,
            })}
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button variant="text" onClick={onClose}>
            {t('globals:btn_cancel')}
          </Button>
          <Button
            id="change-impersonator"
            mr={3}
            onClick={changeImpersonatorHandler}
          >
            {t('globals:btn_save')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
