import { Box, Flex, List, useMediaQuery } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import {
  ChecklistDetails,
  DetailViewContainer,
  Layout,
  LoadingPlaceholder,
  NoItemsPlaceholder,
  ProductDetails,
  SearchDetails,
  SearchInput,
} from '../modules';
import {
  useChecklistControllerGetChecklist,
  useProductControllerGetProduct,
  useSearchControllerSearchAll,
} from '@hooks';
import { GlobalSearchResultDto } from '@models';
import { useDebounce } from 'use-debounce';
import { getSearchResultKey } from '@utils';

export function SearchPage() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDetailsClosed, setIsDetailsClosed] = useState(true);
  const [isMedium] = useMediaQuery('(max-width: 1024px)');

  const [query, setQuery] = useState<string>(searchParams.get('query') || '');
  const [debouncedQuery] = useDebounce(query, 250);
  const [selectedListItem, setSelectedListItem] =
    useState<GlobalSearchResultDto | null>(null);

  const closeDetailsHandler = () => {
    setIsDetailsClosed(true);
  };

  const { data: searchResults, isLoading: isLoadingSearchResults } =
    useSearchControllerSearchAll(
      debouncedQuery,
      {},
      { query: { enabled: !!debouncedQuery } },
    );

  const { data: selectedProduct, refetch: refetchProduct } =
    useProductControllerGetProduct(
      // @ts-expect-error possibly undefined, doesn't matter due to enabled property
      selectedListItem?.id,
      selectedListItem?.productKind,
      {
        query: {
          enabled: !!selectedListItem && selectedListItem.type === 'PRODUCT',
        },
      },
    );

  const { data: selectedChecklist, refetch: refetchChecklist } =
    // @ts-expect-error possibly undefined, doesn't matter due to enabled property
    useChecklistControllerGetChecklist(selectedListItem?.id, {
      query: {
        enabled: !!selectedListItem && selectedListItem.type === 'CHECKLIST',
      },
    });

  function handleSelect(result: GlobalSearchResultDto) {
    setSelectedListItem(result);
    setIsDetailsClosed(false);
  }

  useEffect(() => {
    if (!query) {
      setIsDetailsClosed(true);
      setSelectedListItem(null);
      setSearchParams({});
      return;
    }
    setSearchParams({ query });
  }, [query]);

  return (
    <Layout title="Zoeken">
      <Flex w="full" h="full" p={0} flexDir="row" overflow="hidden">
        <Flex
          h="full"
          w={{ base: isDetailsClosed ? 'full' : 0, md: 'full' }}
          transition="ease-in-out 0.5s"
          gap={4}
          flexDir="column"
          overflow="auto"
        >
          <Flex
            p={{ base: 4, md: 4, lg: 6 }}
            h="full"
            w="full"
            flexDir="column"
            overflow="hidden"
            gap={{ base: 3, md: 4, lg: 6 }}
          >
            <SearchInput query={query} setQuery={setQuery} />
            <Flex h="full" overflow="hidden">
              <Flex
                p={0}
                h="full"
                flex={1}
                display="flex"
                flexDir="column"
                justifyContent="space-between"
                overflow="auto"
              >
                {isLoadingSearchResults ? (
                  <LoadingPlaceholder
                    text={t('search:loading_search_results')}
                  />
                ) : (
                  <List h="full" spacing={{ base: 2, lg: 3 }}>
                    {query && searchResults && !searchResults.data.length && (
                      <NoItemsPlaceholder
                        text={t('search:placeholder_no_search_results')}
                      />
                    )}

                    {searchResults?.data.map((result) => {
                      return (
                        <SearchDetails
                          key={getSearchResultKey(result)}
                          result={result}
                          selectHandler={handleSelect}
                          selectedListItem={selectedListItem}
                        />
                      );
                    })}
                  </List>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          w={{ base: !isDetailsClosed ? 'full' : 0 }}
          transition="ease-in-out 0.4s"
          bgColor="brand.white"
          borderRadius={isMedium ? 'xl' : 'none'}
          borderLeftRadius="xl"
          overflow="hidden"
          m={!isDetailsClosed && isMedium ? 2 : 0}
        >
          <Box
            w="full"
            overflow="hidden"
            bgColor="brand.white"
            borderLeftRadius="xl"
          >
            {selectedListItem && (
              <DetailViewContainer
                handleIsDetailsClosed={closeDetailsHandler}
                label={
                  selectedListItem.type === 'PRODUCT'
                    ? selectedListItem.productKind!
                    : 'Checklist'
                }
                description={selectedListItem.name}
                children={
                  selectedListItem.type === 'CHECKLIST' &&
                  !!selectedChecklist ? (
                    <ChecklistDetails
                      checklist={selectedChecklist!.data}
                      refetch={refetchChecklist}
                    />
                  ) : selectedListItem.type === 'PRODUCT' &&
                    !!selectedProduct ? (
                    <ProductDetails
                      product={selectedProduct!.data}
                      refetch={refetchProduct}
                    />
                  ) : (
                    <Flex h="full" w="full">
                      <LoadingPlaceholder
                        text={t('products:loading_products')}
                      />
                    </Flex>
                  )
                }
              />
            )}
          </Box>
        </Flex>
      </Flex>
    </Layout>
  );
}
