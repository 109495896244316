import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  HStack,
  Icon,
  Avatar,
  VStack,
  ModalFooter,
  useDisclosure,
  Text,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaEdit } from 'react-icons/fa';

import { notifyUser } from '../../../common';
import {
  ProductChecklistDto,
  ProductDetailsDto,
  ProductMemberDto,
} from '@models';
import {
  useChecklistControllerAddMembers,
  useChecklistControllerCreate,
} from '@hooks';

type Props = {
  product: ProductDetailsDto;
  productChecklists: ProductChecklistDto[];
  refetch: () => void;
};

type SelectionItem = {
  label: string;
  value: ProductChecklistDto;
};

type ChecklistAction = 'create' | 'update';

export function ProductChecklistFromAccess({
  product,
  productChecklists,
  refetch,
}: Props) {
  const { t } = useTranslation();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [checklistName, setChecklistName] = useState('');
  const [selectedMembers, setSelectedMembers] = useState<ProductMemberDto[]>(
    [],
  );
  const [ChecklistAction, setChecklistAction] =
    useState<ChecklistAction>('create');
  const [step, setStep] = useState(1);

  const handleChecklistActionChange = (action: ChecklistAction) => {
    setChecklistAction(action);
  };

  const { mutate: createChecklist } = useChecklistControllerCreate({
    mutation: {
      onSuccess: () => {
        notifyUser(
          t('checklists:toast_success_create_checklist', {
            name: checklistName,
          }),
          false,
        );
        setChecklistName('');
      },
      onError: () => {
        notifyUser(t('checklists:toast_error_create_checklist'), true);
      },
      onSettled: () => {
        refetch();
      },
    },
  });

  const { mutate: updateChecklist } = useChecklistControllerAddMembers({
    mutation: {
      onSuccess: () => {
        notifyUser(
          t('checklists:toast_success_edit_checklist', {
            name: checklistName,
          }),
          false,
        );
        setChecklistName('');
      },
      onError: () => {
        notifyUser(t('checklists:toast_error_edit_checklist'), true);
      },
      onSettled: () => {
        refetch();
      },
    },
  });

  const [selectedLinkingItem, setSelectedLinkingItem] =
    useState<SelectionItem | null>(null);

  function handleCreateChecklistFromAccess() {
    if (checklistName === '' && !selectedMembers.length) return;

    createChecklist({
      data: {
        members: selectedMembers.map((a) => a.email),
        name: checklistName,
        products: [product],
        type: 'INTERNAL',
      },
    });
  }

  function handleUpdateChecklistFromAccess() {
    if (!selectedLinkingItem && !selectedMembers.length) return;
    if (!selectedLinkingItem) return;

    updateChecklist({
      id: selectedLinkingItem.value.id,
      data: {
        members: selectedMembers.map((a) => a.email),
      },
    });
  }

  const handleSelectedMembers = (member: ProductMemberDto) => {
    const isSelected = selectedMembers.includes(member);

    setSelectedMembers((prev) =>
      isSelected ? prev.filter((mem) => mem !== member) : [...prev, member],
    );
  };

  return (
    <>
      <Button
        w="fit-content"
        size="sm"
        mb={0}
        onClick={() => {
          setSelectedMembers([]);
          onOpen();
        }}
      >
        <Icon as={FaEdit} />
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset="none">
        <ModalOverlay />
        {step === 1 && (
          <ModalContent>
            <ModalHeader pb={0}>
              {t('products:modal_header_select_members')}
            </ModalHeader>
            <ModalBody pt={0} display="flex" flexDir="column" gap={5}>
              <Text>{t('products:modal_body_select_members')}</Text>
              <Text>
                {t('checklists:selected_member_amount', {
                  memberAmount: selectedMembers.length,
                })}
              </Text>
              {product.members.map((member) => {
                return (
                  <HStack
                    id="select-members"
                    key={member.email}
                    onClick={() => handleSelectedMembers(member)}
                    gap={4}
                    _hover={{ cursor: 'pointer' }}
                  >
                    {selectedMembers.includes(member) && (
                      <Box
                        w={{ base: 12, md: 14 }}
                        h={{ base: 12, md: 14 }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bg="brand.gradient"
                        borderRadius="full"
                        opacity={0.7}
                        position="absolute"
                        zIndex={1}
                      >
                        <Icon
                          color="brand.white"
                          fontSize="x-large"
                          as={FaCheck}
                        />
                      </Box>
                    )}
                    <Avatar
                      w={{ base: 12, md: 14 }}
                      h={{ base: 12, md: 14 }}
                      name={member.name}
                      src={member.photoLink || ''}
                    />
                    <VStack alignItems="flex-start" gap={0} lineHeight={1.2}>
                      <Text>{member.name}</Text>
                      <Text color="brand.secondary">{member.email}</Text>
                    </VStack>
                  </HStack>
                );
              })}
            </ModalBody>
            <ModalFooter
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button variant="text" onClick={onClose}>
                {t('globals:btn_cancel')}
              </Button>
              <Button
                variant="solid"
                isDisabled={!selectedMembers.length}
                onClick={() => {
                  setStep(2);
                }}
              >
                {t('globals:btn_next')}
              </Button>
            </ModalFooter>
          </ModalContent>
        )}
        {step === 2 && (
          <ModalContent>
            <ModalHeader pb={0}>
              {t('products:modal_header_select_members')}
            </ModalHeader>
            <ModalBody pt={0} w="full" display="flex" flexDir="column" gap={5}>
              <Text>{t('products:modal_body_manage_checklist')}</Text>
              <RadioGroup
                display="flex"
                flexDir="column"
                gap={8}
                value={ChecklistAction}
                onChange={handleChecklistActionChange}
              >
                <HStack>
                  <Flex w="full" display="column">
                    <Text ml={9} mb={1}>
                      {t('products:create_checklist')}
                    </Text>
                    <FormControl>
                      <InputGroup display="flex" gap={3}>
                        <Radio
                          variant="primary"
                          value="create"
                          colorScheme="radio"
                        />
                        <Input
                          placeholder={t(
                            'checklists:placeholder_create_checklist',
                          )}
                          bg="brand.gray"
                          borderColor="brand.white"
                          _hover={{ borderColor: 'brand.white' }}
                          value={checklistName}
                          onChange={(e) => setChecklistName(e.target.value)}
                          isDisabled={ChecklistAction !== 'create'}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {t('checklists:invalid_checklist')}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                </HStack>
                <HStack w="full">
                  <Flex w="full" flexDir="column">
                    <Text ml={9} mb={1}>
                      {t('products:update_checklist')}
                    </Text>
                    <InputGroup zIndex={1} w="full" display="flex" gap={3}>
                      <Radio value="update" colorScheme="radio" />
                      <Select
                        menuPlacement="auto"
                        placeholder={t(
                          'products:placeholder_search_checklists',
                        )}
                        options={productChecklists?.map((c) => {
                          return {
                            label: c.name,
                            value: c,
                          };
                        })}
                        value={selectedLinkingItem}
                        onChange={(item) => {
                          if (!item) return;
                          setSelectedLinkingItem(item);
                        }}
                        isDisabled={ChecklistAction !== 'update'}
                        chakraStyles={{
                          control: (base) => ({
                            ...base,
                            bg: 'brand.gray',
                            color: 'brand.primary',
                            borderColor: 'brand.gray',
                            borderRadius: 'lg',
                            maxW: 'full',
                            _hover: {
                              cursor: 'text',
                            },
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            bg: 'brand.gradient',
                            color: 'brand.white',
                          }),
                          placeholder: (base) => ({
                            ...base,
                            color: 'brand.secondary',
                          }),
                          container: (provided) => ({
                            ...provided,
                            w: 'full',
                            m: 1,
                          }),
                        }}
                      />
                    </InputGroup>
                  </Flex>
                </HStack>
              </RadioGroup>
            </ModalBody>
            <ModalFooter
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                variant="text"
                onClick={() => {
                  setStep(1);
                  setSelectedLinkingItem(null);
                  setChecklistName('');
                }}
              >
                {t('globals:btn_back')}
              </Button>
              <Button
                id="create-checklist-from-access"
                variant="solid"
                onClick={(event) => {
                  event.preventDefault();
                  ChecklistAction === 'create' &&
                    handleCreateChecklistFromAccess();
                  ChecklistAction === 'update' &&
                    handleUpdateChecklistFromAccess();
                  onClose();
                  setStep(1);
                }}
              >
                {t('globals:btn_continue')}
              </Button>
            </ModalFooter>
          </ModalContent>
        )}
      </Modal>
    </>
  );
}
