import { CloseIcon } from '@chakra-ui/icons';
import {
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';

type Props = {
  query: string;
  setQuery: (query: string) => void;
};

export function SearchInput({ query, setQuery }: Props) {
  const { t } = useTranslation();
  return (
    <InputGroup>
      <InputLeftElement>
        <FaSearch
          style={{
            margin: '0 4px',
          }}
        />
      </InputLeftElement>
      <Input
        autoFocus
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        bg="brand.white"
        placeholder={t('search:placeholder_search')}
        borderColor="transparent"
        outlineColor="transparent"
        _focusVisible={{
          boxShadow: 'none',
        }}
        _hover={{
          outline: 'none',
        }}
      />
      {query && (
        <InputRightElement
          onClick={() => setQuery('')}
          _hover={{ cursor: 'pointer' }}
        >
          <CloseIcon w={3} h={3} />
        </InputRightElement>
      )}
    </InputGroup>
  );
}
