import { GlobalSearchResultDto } from '@models';

export const getSearchResultKey = (result: GlobalSearchResultDto): string => {
  let key: string;
  if (result.type === 'CHECKLIST') {
    key = `user__${result.id}`;
  } else {
    key = `product__${result.id}__${result.productKind}`;
  }
  return key;
};
