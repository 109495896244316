import { CloseIcon } from '@chakra-ui/icons';
import {
  VStack,
  HStack,
  FormControl,
  Input,
  FormErrorMessage,
  Button,
  Text,
  Icon,
  useDisclosure,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdEdit } from 'react-icons/md';

import { ImpersonatorModal } from './ChangeImpersonatorModal';

type Props = {
  updateHandler: (email: string) => void;
  impersonator: string;
};

export function ChangeImpersonator({ updateHandler, impersonator }: Props) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [email, setEmail] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  function handleChangeImpersonator() {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email)) {
      setEmailIsInvalid(true);
      return;
    }
    setEmailIsInvalid(false);
    setIsProcessing(true);
    updateHandler(email);
    onClose();
    setEmail('');
    setIsProcessing(false);
  }

  return (
    <VStack w="full" alignItems="start" mt="auto" gap={{ base: 1, md: 2 }}>
      <Text ml={2} fontSize={{ base: 'sm', md: 'md' }}>
        {t('settings:label_update_impersonator')}
      </Text>
      <HStack w="full" alignItems="flex-start">
        <FormControl isInvalid={emailIsInvalid}>
          <InputGroup>
            <Input
              type="email"
              placeholder={t('settings:placeholder_email')}
              size="sm"
              bg="brand.gray"
              borderColor="brand.gray"
              borderRadius="md"
              pl="5"
              color="brand.primary"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isDisabled={isProcessing}
              _placeholder={{ color: 'brand.secondary' }}
              onKeyPress={(event) => {
                setEmailIsInvalid(false);
                if (event.key !== 'Enter') return;
                onOpen();
              }}
            />
            {email && (
              <InputRightElement h="full">
                <CloseIcon
                  boxSize="0.8rem"
                  _hover={{ cursor: 'pointer' }}
                  onClick={() => setEmail('')}
                />
              </InputRightElement>
            )}
          </InputGroup>
          {emailIsInvalid && (
            <FormErrorMessage>{t('settings:invalid_email')}</FormErrorMessage>
          )}
        </FormControl>
        <Button
          isLoading={isProcessing}
          _hover={{ _disabled: { bg: 'inherit' } }}
          size="sm"
          border="none"
          onClick={onOpen}
          alignSelf="flex-start"
          isDisabled={!email}
        >
          <Icon as={MdEdit} />
        </Button>
        <ImpersonatorModal
          isOpen={isOpen}
          onClose={onClose}
          changeImpersonatorHandler={handleChangeImpersonator}
          impersonator={impersonator}
          newImpersonator={email}
        />
      </HStack>
    </VStack>
  );
}
