import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IAuthContextProps } from '..';
import {
  useAuthControllerGetMe,
  useAuthControllerLogin,
  useAuthControllerLogout,
} from '@hooks';
import { LoginInfoDto, UserDto } from '@models';
import { notifyUser } from '../..';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

axios.defaults.withCredentials = true;

export const AuthContext = createContext<IAuthContextProps>(
  {} as IAuthContextProps,
);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [user, setUser] = useState<UserDto | null>(null);
  const [loginObject, setLoginObject] = useState<Omit<
    LoginInfoDto,
    'user'
  > | null>(null);

  const [isLoadingMe, setIsLoadingMe] = useState(true);

  const {
    data: me,
    error: meError,
    isError: meHasErrored,
  } = useAuthControllerGetMe({
    query: {
      retry: false,
    },
  });

  useEffect(() => {
    const status = meError?.response?.status;
    if (meHasErrored && status === 403) {
      logout();
      setIsLoadingMe(false);
      return;
    }

    if (me?.data) {
      const { user, ...metadata } = me.data;
      setUser(user);
      setLoginObject(metadata);
      setIsLoadingMe(false);
    }
  }, [me, meHasErrored, meError]);

  const { mutateAsync: apiLogin, isPending } = useAuthControllerLogin({
    mutation: {
      onError: (error) => {
        console.error(error);
        if (error.status === 401) {
          notifyUser(t('login:toast_error_unauthorized'), true);
        } else {
          notifyUser(t('login:toast_error_google_login'), true);
        }
      },
      onSuccess: (data) => {
        const { user, ...metadata } = data.data;
        setLoginObject(metadata);
        setUser(user);
      },
    },
  });

  const { mutate: logoutMutation } = useAuthControllerLogout({
    mutation: {
      onError: () => {
        throw new Error('Failed to log out');
      },
      onSuccess: () => {
        navigate('/login');
      },
    },
  });

  const login = async (code: string) => {
    await apiLogin({ params: { code } });
  };

  const logout = () => {
    logoutMutation();
    setUser(null);
  };

  const isAuthenticated = useMemo(() => {
    const hasAuth = !!user && !isPending && !isLoadingMe;
    return hasAuth;
  }, [user, isPending, isLoadingMe]);

  const isLoading = useMemo(() => {
    return isPending || isLoadingMe;
  }, [isPending, isLoadingMe]);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isAuthenticated,
        loginObject,
        user,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
