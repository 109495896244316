import {
  FormControl,
  FormLabel,
  Input,
  Text,
  FormErrorMessage,
} from '@chakra-ui/react';
import { Field, FieldProps } from 'formik';

type Props = {
  name: string;
  label: string;
  error?: string;
  isRequired?: boolean;
};

export function SubscriptionFormField({
  name,
  label,
  error,
  isRequired = true,
}: Props) {
  return (
    <Field type="text" name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl isInvalid={!!form.errors[name]} mb={5}>
          <FormLabel display="flex" gap={1} ml={2}>
            {label}
            {isRequired && <Text color="red.300">*</Text>}
          </FormLabel>
          <Input variant="solid" {...field} />
          <FormErrorMessage position="absolute" mt={1} ml={2}>
            {error}
          </FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
}
