import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { InfoCard } from '../modules';

export function PendingSyncPage() {
  const { t } = useTranslation();

  return (
    <Flex
      w="full"
      h="full"
      zIndex={0}
      bgImage="url(/background.png)"
      bgSize="cover"
      bgPosition="center"
      overflow="hidden"
      justifyContent="center"
      alignItems="center"
      flexDir={{ base: 'column', lg: 'row' }}
      p={{ base: 4, lg: 12 }}
    >
      <InfoCard
        type={'sync'}
        subtitle={t('subscriptions:info_sync_subtitle')}
        title={t('subscriptions:info_sync_title')}
        message={t('subscriptions:info_sync_message')}
      />
    </Flex>
  );
}
