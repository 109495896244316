import {
  Box,
  Heading,
  Accordion,
  AccordionPanel,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  Button,
  Text,
  Flex,
  VStack,
  Link,
} from '@chakra-ui/react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

export const ErrorFallback: React.ComponentType<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const DEVELOPMENT = import.meta.env.DEV;

  function handleOnBack() {
    resetErrorBoundary();
  }

  const { t } = useTranslation();

  return (
    <Flex
      w="full"
      h="full"
      flexDir="column"
      zIndex={0}
      p={0}
      m={0}
      bgImage="url(/background.png)"
      bgSize="cover"
      bgPosition="center"
      justifyContent="center"
      alignItems="center"
      color="#323E48"
    >
      <Flex
        position="absolute"
        w="full"
        h="full"
        opacity={0.96}
        zIndex={0}
      ></Flex>
      <VStack w="full" maxW="4xl" zIndex={1} p={8} gap={4}>
        <Flex
          flexDir="column"
          w="full"
          as="header"
          textAlign={{ base: 'start', sm: 'center' }}
          gap={3}
        >
          <Heading
            as="h1"
            fontSize="3xl"
            fontWeight="bold"
            color="brand.primary"
            mb={2}
          >
            {t('fallback:error_header')}
          </Heading>
          {!DEVELOPMENT && (
            <>
              <Text fontSize="md">{t('fallback:error_description')}</Text>
              <Text fontSize="md">
                {t('fallback:error_contact')}
                <Link
                  href="mailto: support@zzapps.nl"
                  fontWeight="medium"
                  _hover={{ textDecor: 'none' }}
                >
                  support@zzapps.nl
                </Link>
              </Text>
              <Text fontSize="md">{t('fallback:error_apology')}</Text>
            </>
          )}
        </Flex>
        {DEVELOPMENT && (
          <Accordion w="full" allowToggle>
            <AccordionItem bgColor="gray.50" borderRadius="md">
              <AccordionButton
                display="flex"
                justifyContent="space-between"
                borderColor="brand.white"
                _hover={{ bg: 'brand.white', borderRadius: 'md' }}
                py={3}
              >
                <Heading
                  as="h2"
                  fontSize="md"
                  fontWeight="medium"
                  textAlign="start"
                >
                  {error.message}
                </Heading>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel
                borderTop="1px solid #e8e8e8"
                pb={4}
                bg="white"
                lineHeight="1.9"
              >
                {error.stack}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        )}

        <Box
          as="footer"
          w="full"
          display="flex"
          justifyContent={{ base: 'start', sm: 'center' }}
        >
          <Button onClick={handleOnBack} mt={2} bg="brand.gradient">
            {t('fallback:btn_try_again')}
          </Button>
        </Box>
      </VStack>
    </Flex>
  );
};
