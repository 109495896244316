import { createStandaloneToast } from '@chakra-ui/react';

export const notifyUser = (description: string, error: boolean = false) => {
  const { toast } = createStandaloneToast();

  toast({
    position: 'top-right',
    description: description,
    status: error ? 'error' : 'success',
    duration: 5000,
    isClosable: true,
  });
};
