import { Navigate, useLocation } from 'react-router-dom';

import { ReactNode } from 'react';
import { useSubscriptionControllerGetMyCustomer } from '@hooks';

type Props = {
  children: ReactNode;
};

export function RequireSubscription({ children }: Props) {
  const { data, isLoading } = useSubscriptionControllerGetMyCustomer();
  const { pathname } = useLocation();

  if (isLoading) return null;
  if (!data?.data.hasActiveSubscription) {
    if (pathname.startsWith('/settings/subscription')) {
      return children;
    }
    return (
      <Navigate
        replace
        to={{
          pathname: '/settings/subscription',
          search: `redirect=${pathname}`,
        }}
      />
    );
  }

  return children;
}
