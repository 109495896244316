import {
  HStack,
  Icon,
  Link,
  Text,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { Link as ReactLink, useLocation } from 'react-router-dom';

type Props = {
  name: string;
  routeUrl: string;
  icon: IconType;
};

export function NavigationLink({ name, icon, routeUrl }: Props) {
  const [isExtraLarge] = useMediaQuery('(min-width: 80em)');
  const location = useLocation();

  function isActive(routeUrl: string) {
    return location.pathname.includes(routeUrl);
  }

  return (
    <Tooltip
      w="full"
      h="full"
      label={name}
      hasArrow
      placement="right"
      isDisabled={isExtraLarge}
    >
      <Link
        w={{ base: 'fit-content', md: 'full' }}
        as={ReactLink}
        to={routeUrl}
        mb={0}
        variant="sideBar"
        color={isActive(routeUrl) ? 'brand.white' : 'brand.secondary'}
        bg={isActive(routeUrl) ? 'brand.gradient' : 'none'}
        _hover={{
          color: isActive(routeUrl) ? 'brand.white' : 'brand.primary',
        }}
        position="relative"
        p={4}
        borderRadius="md"
      >
        <HStack alignItems="center" justifyContent="start" gap={4}>
          <Icon as={icon} boxSize={4} />
          <Text
            fontSize="sm"
            fontWeight="normal"
            whiteSpace="nowrap"
            display={{ base: 'none', xl: 'block' }}
          >
            {name}
          </Text>
        </HStack>
      </Link>
    </Tooltip>
  );
}
