import { Flex } from '@chakra-ui/react';

type Props = {
  children: JSX.Element[];
};

export function HeaderMenuContainer({ children }: Props) {
  return (
    <Flex
      as="nav"
      w="full"
      flexDir="column"
      bg="brand.white"
      position="absolute"
      borderBottom="1px solid"
      borderColor="brand.gray"
    >
      {children}
    </Flex>
  );
}
