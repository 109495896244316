import {
  Button,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { useState } from 'react';
import { IoMdAddCircle } from 'react-icons/io';

import { useChecklistControllerCreate } from '@hooks';
import { notifyUser } from '../../../common';
import { ChecklistDto } from '@models';

type Props = {
  selectionHandler: (checklist: ChecklistDto) => void;
  refetchChecklists: () => void;
};

export function ChecklistCreationModal({
  selectionHandler,
  refetchChecklists,
}: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [checklistName, setChecklistName] = useState('');

  const { mutate: createChecklist } = useChecklistControllerCreate({
    mutation: {
      onError: () => {
        notifyUser(t('checklists:toast_error_create_checklist'), true);
      },
      onSuccess: (data) => {
        notifyUser(
          t('checklists:toast_success_create_checklist', {
            name: data.data.name,
          }),
          false,
        );
        setChecklistName('');
        selectionHandler({ ...data.data });
        onClose();
      },
      onSettled: () => {
        refetchChecklists();
      },
    },
  });

  const handleCreateChecklist = async () => {
    if (!checklistName) return;
    createChecklist({
      data: {
        name: checklistName,
        members: null,
        products: null,
        type: 'INTERNAL',
      },
    });
  };

  return (
    <>
      <Button
        w="fit-content"
        variant="solid"
        onClick={onOpen}
        leftIcon={<IoMdAddCircle size={20} />}
      >
        {t('checklists:btn_create_checklist')}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pb={0}>
            {t('checklists:modal_header_create_checklist')}
          </ModalHeader>
          <ModalBody pt={0} w="full" display="flex" flexDir="column" gap={5}>
            <Text>{t('checklists:modal_body_create_checklist')}</Text>
            <FormControl>
              <Input
                placeholder={t('checklists:placeholder_create_checklist')}
                bg="brand.gray"
                borderColor="brand.white"
                _hover={{ borderColor: 'brand.white' }}
                value={checklistName}
                onChange={(e) => setChecklistName(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    handleCreateChecklist();
                  }
                }}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="text"
              onClick={() => {
                setChecklistName('');
                onClose();
              }}
            >
              {t('globals:btn_cancel')}
            </Button>
            <Button
              id="create-checklist"
              isDisabled={!checklistName}
              mr={3}
              onClick={handleCreateChecklist}
            >
              {t('globals:btn_save')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
