import { WarningIcon } from '@chakra-ui/icons';
import { HStack, ListItem, Tooltip, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type Props = {
  email: string;
  isPartOfChecklist: boolean;
};

export function GroupMember({ email, isPartOfChecklist }: Props) {
  const { t } = useTranslation();

  return (
    <ListItem ml={16} color="inherit">
      <HStack>
        {!isPartOfChecklist && (
          <Tooltip label={t('products:tooltip_deviation')} placement="top">
            <WarningIcon
              data-testid="warning-icon"
              w="4"
              h="4"
              mr="2"
              color="brand.secondary"
            />
          </Tooltip>
        )}
        <Text fontSize="sm">{email}</Text>
      </HStack>
    </ListItem>
  );
}
