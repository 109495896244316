import { Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ChecklistMembersListItem } from './ChecklistMembersListItem';

type ChecklistMemberListProps = {
  members: string[];
  isLoading: boolean;
  deleteHandler(member: string): void;
  isReadonly: boolean;
};

export function ChecklistMembersList({
  members,
  deleteHandler,
  isReadonly,
}: ChecklistMemberListProps) {
  const { t } = useTranslation();

  return (
    <VStack h="full" alignItems="space-between" mb={5} overflow="auto" gap={0}>
      {members.map((member) => (
        <ChecklistMembersListItem
          key={member}
          member={member}
          deleteHandler={deleteHandler}
          isReadonly={isReadonly}
        />
      ))}
      {!members ||
        (members.length === 0 && (
          <Text my={4} textAlign="center">
            {t('checklists:placeholder_no_members')}
          </Text>
        ))}
    </VStack>
  );
}
