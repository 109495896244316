import { Tab } from '@chakra-ui/react';

type Props = {
  name: string;
  onClickHandler?: () => void;
};

export function CustomTab({ name, onClickHandler }: Props) {
  return (
    <Tab
      textTransform="capitalize"
      border="none"
      m={0}
      fontSize={{ base: 'xs', md: 'xs' }}
      _selected={{
        borderRadius: 'md',
        background: 'brand.gradient',
        color: 'brand.white',
      }}
      _active={{
        borderRadius: 'md',
        background: 'brand.gradient',
        color: 'brand.white',
      }}
      onClick={onClickHandler}
    >
      {name}
    </Tab>
  );
}
