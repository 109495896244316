import {
  Flex,
  VStack,
  Heading,
  Image,
  Link,
  Text,
  Progress,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink } from 'react-router-dom';
import { useAuth } from 'src/modules/auth';

type Props = {
  type: 'success' | 'warning' | 'error' | 'info' | 'install' | 'sync';
  subtitle: string;
  title: string;
  message: string;
  linkLabel?: string;
  link?: string;
  target?: string;
  handleOnClick?: () => void;
};

export function InfoCard({
  type,
  subtitle,
  title,
  message,
  linkLabel,
  link,
  target,
  handleOnClick,
}: Props) {
  const { t } = useTranslation();
  const { logout } = useAuth();

  return (
    <Flex
      bg="brand.white"
      position="relative"
      borderRadius="md"
      p={{ base: 6, lg: 12 }}
      py={12}
    >
      <VStack alignItems="center" gap={10} maxW="md">
        <Image src={`/${type}.svg`} alt={`Page status ${type}`} />
        {type === 'sync' && (
          <Progress
            w="50%"
            bgImage="brand.gradient"
            borderRadius="full"
            isIndeterminate
            size="xs"
            sx={{
              '& > div': {
                background:
                  'linear-gradient(225deg ,#D6006D 0%, #7A2563 70%, #2B445A 300%)',
              },
            }}
          />
        )}
        <VStack>
          <Text fontSize="md" fontWeight="bold" textTransform="uppercase">
            {subtitle}
          </Text>
          <Heading
            as="h2"
            fontSize={{ base: 'xl', lg: '2xl' }}
            fontWeight="bold"
            textTransform="uppercase"
            bg={`brand.gradient-${type}`}
            bgClip="text"
            textAlign="center"
          >
            {title}
          </Heading>
          <Text fontSize="md" textAlign="center" mt={4}>
            {message}
          </Text>
        </VStack>
        <VStack gap={5}>
          {linkLabel && (
            <Link
              as={ReactLink}
              to={link}
              target={target}
              onClick={handleOnClick}
              bg={`brand.gradient-${type}`}
              px={4}
              py={2}
              borderRadius="md"
              color="white"
              _hover={{ textDecor: 'none' }}
            >
              {linkLabel}
            </Link>
          )}
          <VStack w="full" gap={0} mt={10}>
            <Text fontSize="sm">{t('globals:label_wrong_account')}</Text>
            <Button
              w="full"
              variant="text"
              fontSize="sm"
              onClick={() => logout()}
            >
              {t('sidebar:btn_logout')}
            </Button>
          </VStack>
        </VStack>
      </VStack>
    </Flex>
  );
}
