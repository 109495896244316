import {
  VStack,
  HStack,
  FormControl,
  Input,
  FormErrorMessage,
  Button,
  Text,
  Icon,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdPersonAdd } from 'react-icons/io';

type AddAuthorizedUserProps = {
  addHandler: (email: string) => void;
};

export function AddAuthorizedUser({ addHandler }: AddAuthorizedUserProps) {
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [email, setEmail] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const { t } = useTranslation();

  function handleAddMember() {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email)) {
      setEmailIsInvalid(true);
      return;
    }
    setEmailIsInvalid(false);
    setIsProcessing(true);
    addHandler(email);
    setEmail('');
    setIsProcessing(false);
  }

  return (
    <VStack w="full" alignItems="start" mt="auto" gap={{ base: 1, md: 2 }}>
      <Text ml={2} fontSize={{ base: 'sm', md: 'md' }}>
        {t('settings:label_add_authorized_user')}
      </Text>
      <HStack w="full" alignItems="flex-start">
        <FormControl isInvalid={emailIsInvalid}>
          <Input
            size="sm"
            data-testid="add-member"
            bg="brand.gray"
            borderColor="brand.gray"
            id="email"
            type="email"
            placeholder={t('settings:placeholder_email')}
            borderRadius="md"
            pl="5"
            value={email}
            color="brand.primary"
            onChange={(e) => setEmail(e.target.value)}
            isDisabled={isProcessing}
            _placeholder={{ color: 'brand.secondary' }}
            onKeyPress={(event) => {
              setEmailIsInvalid(false);
              if (event.key !== 'Enter') return;
              handleAddMember();
            }}
          />
          {emailIsInvalid && (
            <FormErrorMessage>{t('settings:invalid_email')}</FormErrorMessage>
          )}
        </FormControl>
        <Button
          id="add-member"
          isLoading={isProcessing}
          _hover={{ _disabled: { bg: 'inherit' } }}
          size="sm"
          border="none"
          onClick={handleAddMember}
          alignSelf="flex-start"
          isDisabled={isProcessing || !email}
        >
          <Icon as={IoMdPersonAdd} />
        </Button>
      </HStack>
    </VStack>
  );
}
