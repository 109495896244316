import { useTranslation } from 'react-i18next';

import { notifyUser } from '../../common';

import { AddAuthorizedUser } from './AddAuthorizedUser';
import { AuthorizedUsersList } from './AuthorizedUsersList';
import { SettingsContent } from './SettingsContent';
import {
  useTenantControllerAddAuthorizedUser,
  useTenantControllerGetAuthorizedUsers,
  useTenantControllerRemoveAuthorizedUser,
} from '@hooks';

export function AuthorizedUsersSettings() {
  const { t } = useTranslation();

  const {
    data: authorizedUsers,
    isLoading: isLoadingAuthorizedUsers,
    refetch,
  } = useTenantControllerGetAuthorizedUsers();

  const { mutate: addUser } = useTenantControllerAddAuthorizedUser({
    mutation: {
      onSuccess: () =>
        notifyUser(t('settings:toast_success_add_authorized_user'), false),
      onError: () =>
        notifyUser(t('settings:toast_error_add_authorized_user'), true),
      onSettled: () => refetch(),
    },
  });

  const { mutate: removeUser } = useTenantControllerRemoveAuthorizedUser({
    mutation: {
      onSuccess: () =>
        notifyUser(t('settings:toast_success_remove_authorized_user'), false),
      onError: () =>
        notifyUser(t('settings:toast_error_remove_authorized_user'), true),
      onSettled: () => refetch(),
    },
  });

  const handleAddUser = (user: string) => {
    addUser({ data: { email: user } });
  };

  const handleDeleteUser = (user: string) => {
    removeUser({ data: { email: user } });
  };

  return (
    <SettingsContent
      title={t('settings:title_authorized_users')}
      description={t('settings:description_authorized_users')}
    >
      {!isLoadingAuthorizedUsers && (
        <AuthorizedUsersList
          users={authorizedUsers!.data.users}
          deleteHandler={handleDeleteUser}
        />
      )}
      <AddAuthorizedUser addHandler={handleAddUser} />
    </SettingsContent>
  );
}
