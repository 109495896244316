import { Flex } from '@chakra-ui/react';

type Props = {
  children: JSX.Element[];
};

export function HeaderContainer({ children }: Props) {
  return (
    <Flex
      bg="brand.white"
      h="50px"
      alignItems="center"
      justifyContent="space-between"
      px={6}
      borderBottom="1px solid"
      borderColor="brand.gray"
      borderBottomRadius="lg"
    >
      {children}
    </Flex>
  );
}
