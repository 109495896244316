import {
  VStack,
  HStack,
  Heading,
  Button,
  Box,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { LoadingPlaceholder } from '../../common';
import { useSubscriptionControllerUpdateRenewal } from '@hooks';

import { CancelSubscriptionModal } from './CancelSubscriptionModal';
import { SubscriptionUserDetails } from './SubscriptionUserDetails';
import {
  GetBillingInfoDto,
  TenantSubscriptionStatusDto,
  TenantSubscriptionStatusDtoSubscriptionType,
} from '@models';

type Props = {
  billingInfo: GetBillingInfoDto;
  tenant: TenantSubscriptionStatusDto;
};

export function SubscriptionDetails({ billingInfo, tenant }: Props) {
  const { t } = useTranslation();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const hasRenewenabled = tenant.renewEnabled;

  const { mutate: updateRenewSubscription, isPending } =
    useSubscriptionControllerUpdateRenewal();

  const handleRenewSubscription = () => {
    updateRenewSubscription();
  };

  if (isPending)
    return <LoadingPlaceholder text={t('subscriptions:loading_renewal')} />;

  return (
    <VStack w="full" h="full" alignItems="flex-start" mb={8}>
      <SubscriptionUserDetails billingInfo={billingInfo} />

      {tenant.subscriptionType !==
        TenantSubscriptionStatusDtoSubscriptionType.CUSTOM && (
        <VStack w="full" alignItems="flex-start">
          <HStack w="full" gap={5}>
            <Heading as="h2" size="md" fontWeight="medium">
              {t('subscriptions:subheading_subscription')}
            </Heading>
            <Box h="1px" w="full" bg="gray.300" mt={1} flex={1}></Box>
          </HStack>
          {tenant.renewEnabled ? (
            <Text>{t('subscriptions:placeholder_status_active')}</Text>
          ) : (
            <Text>{t('subscriptions:placeholder_status_inactive')}</Text>
          )}

          {hasRenewenabled && (
            <Text>
              {t('subscriptions:placeholder_pricing', {
                annual: t(
                  `subscriptions:options_annual_pricing.${tenant.subscriptionType}`,
                ),
              })}
            </Text>
          )}
          <Text>
            {t('subscriptions:placeholder_remaining', {
              days: tenant.daysRemaining,
            })}
          </Text>
          {tenant.renewEnabled && (
            <Button variant="text" ml={-4} mt={4} onClick={onOpen}>
              {t('subscriptions:btn_cancel')}
            </Button>
          )}
          <CancelSubscriptionModal
            onClose={onClose}
            isOpen={isOpen}
            onHandleRenewSubscription={handleRenewSubscription}
            days={tenant.daysRemaining}
          />
        </VStack>
      )}
    </VStack>
  );
}
