import { Box, Flex, FormControl, HStack, Text, VStack } from '@chakra-ui/react';
import { useSearchControllerSearchProducts } from '@hooks';
import { ProductSearchResultDto } from '@models';
import { Select } from 'chakra-react-select';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

type Props = {
  handler: (product: ProductSearchResultDto) => void;
  checklistIds: string[];
};

export function LinkProduct({ handler, checklistIds }: Props) {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 250);
  const [product, setProduct] = useState<ProductSearchResultDto | null>(null);

  const {
    data: results,
    isLoading,
    isFetching,
  } = useSearchControllerSearchProducts(
    debouncedQuery!,
    {},
    {
      query: {
        enabled: !!debouncedQuery,
      },
    },
  );

  const linkableProducts: ProductSearchResultDto[] = useMemo(() => {
    const linkable: ProductSearchResultDto[] = [];
    if (!results) return linkable;
    for (const product of results.data) {
      if (checklistIds.find((id) => id === product.id)) continue;
      linkable.push(product);
    }
    return linkable;
  }, [results, checklistIds]);

  return (
    <Flex
      gap={0}
      flexDir="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Text ml={2} mb={{ base: 0, md: 1 }} fontSize={{ base: 'sm', md: 'md' }}>
        Product toevoegen
      </Text>
      <HStack w="full">
        <Box display="flex" w="full" m={1} gap={2}>
          <FormControl isInvalid={false}>
            <Select
              size="sm"
              menuPlacement="top"
              menuPosition="fixed"
              placeholder={t('checklists:placeholder_search_products')}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              options={linkableProducts}
              onChange={(item) => {
                if (!item) return;
                setQuery('');
                setProduct(null);
                handler(item);
              }}
              formatOptionLabel={(data, meta) =>
                meta.context === 'menu' ? (
                  <VStack gap={0} alignItems="flex-start" overflow="hidden">
                    <Box
                      textTransform="lowercase"
                      fontSize="sm"
                      color="gray.400"
                      dangerouslySetInnerHTML={{
                        __html: data.kind,
                      }}
                    />
                    <Box
                      color="brand.primary"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      w="full"
                      dangerouslySetInnerHTML={{
                        __html: data.highlightedName!,
                      }}
                    />
                  </VStack>
                ) : (
                  <Box overflow="hidden" w="full">
                    <Text
                      color="brand.primary"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      w="full"
                    >
                      {product
                        ? data?.name
                        : t('checklists:placeholder_search_products')}
                    </Text>
                  </Box>
                )
              }
              isLoading={isFetching || isLoading}
              inputValue={query}
              onInputChange={(value, action) => {
                if (
                  action?.action !== 'input-blur' &&
                  action?.action !== 'menu-close'
                ) {
                  setQuery(value);
                }
              }}
              filterOption={null}
              backspaceRemovesValue={true}
              onBlur={(e) => {
                e.preventDefault();
              }}
              chakraStyles={{
                menu: (base, { selectProps }) => ({
                  ...base,
                  display:
                    selectProps.options.length < 1 ? 'none' : 'inline-flex',
                  top: 'auto',
                  bottom: '100%',
                  marginBottom: '35px',
                }),
                menuList: (base) => ({
                  ...base,
                  overflowX: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }),
                control: (base) => ({
                  ...base,
                  bg: 'brand.gray',
                  color: 'brand.primary',
                  borderColor: 'brand.gray',
                  borderRadius: 'lg',
                  _hover: {
                    cursor: 'text',
                  },
                }),
                placeholder: (base) => ({
                  ...base,
                  color: 'brand.secondary',
                }),
              }}
            />
          </FormControl>
        </Box>
      </HStack>
    </Flex>
  );
}
