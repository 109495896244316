import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Switch,
  VStack,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { notifyUser } from '../../common';
import {
  getTenantControllerGetBillingInfoQueryKey,
  useSubscriptionControllerCreateSubscription,
  useTenantControllerSetBillingInfo,
} from '@hooks';

import { SubscriptionFormField } from './SubscriptionFormField';
import { Subscription } from './SubscriptionSettings';
import { GetBillingInfoDto } from '@models';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
  billingInfo: GetBillingInfoDto | null;
  handleCancelBillingInformation: () => void;
  selectedSubscription?: Subscription;
};

export const SubscriptionForm = ({
  billingInfo,
  handleCancelBillingInformation,
  selectedSubscription,
}: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate: updateBillingInformation } =
    useTenantControllerSetBillingInfo({
      mutation: {
        onError: () => notifyUser('Failed to update billing information', true),
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: getTenantControllerGetBillingInfoQueryKey(),
          });
          notifyUser('Billing information successfully updated', false);
        },
      },
    });

  const { mutate: createSubscription, isPending: isLoadingTransactionUrl } =
    useSubscriptionControllerCreateSubscription({
      mutation: {
        onSuccess: (data) => {
          window.open(data.data.transactionUrl, '_self');
        },
        onError: () =>
          notifyUser('Failed to redirect to transaction page', true),
      },
    });

  const subscriptionFormSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('subscriptions:form_invalid_email'))
      .required(t('subscriptions:form_required_email')),
    firstName: yup.string(),
    lastName: yup.string(),
    companyName: yup
      .string()
      .required(t('subscriptions:form_required_company')),
    address: yup.string().required(t('subscriptions:form_required_address')),
    postcode: yup
      .string()
      .required(t('subscriptions:form_required_postal_code')),
    city: yup.string().required(t('subscriptions:form_required_city')),
    country: yup.string().required(t('subscriptions:form_required_country')),
    phone: yup.string(),
    newsletter: yup.boolean(),
    vatNumber: yup.string().required('VAT number is required'),
  });

  return (
    <Formik
      initialValues={{
        email: billingInfo?.email || '',
        firstName: billingInfo?.firstName || '',
        lastName: billingInfo?.lastName || '',
        companyName: billingInfo?.companyName || '',
        address: billingInfo?.address || '',
        postcode: billingInfo?.postcode || '',
        city: billingInfo?.city || '',
        country: billingInfo?.country || '',
        phone: billingInfo?.phone || '',
        newsletter: billingInfo?.newsletter || false,
        vatNumber: billingInfo?.vatNumber || '',
      }}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={(values) => {
        updateBillingInformation({
          data: {
            ...values,
          },
        });
        handleCancelBillingInformation();
        if (selectedSubscription)
          createSubscription({ data: { type: selectedSubscription.type } });
      }}
      validationSchema={subscriptionFormSchema}
    >
      {({ isSubmitting, errors }) => {
        return (
          <Form style={{ width: '100%' }}>
            <VStack w="full" gap={4}>
              <HStack w="full" mt={2}>
                <Heading as="h2" size="md" fontWeight="medium">
                  {t('subscriptions:subheading_personal')}
                </Heading>
                <Box h="1px" w="full" bg="gray.300" mt={1}></Box>
              </HStack>
              <HStack w="full" mt={2}>
                <SubscriptionFormField
                  name="firstName"
                  label={t('subscriptions:label_first_name')}
                  error={errors.firstName}
                  isRequired={false}
                />
                <SubscriptionFormField
                  name="lastName"
                  label={t('subscriptions:label_last_name')}
                  error={errors.lastName}
                  isRequired={false}
                />
              </HStack>
              <SubscriptionFormField
                name="companyName"
                label={t('subscriptions:label_company')}
                error={errors.companyName}
              />
              <SubscriptionFormField
                name="vatNumber"
                label={t('subscriptions:label_vat_number')}
                error={errors.vatNumber}
              />
              <HStack w="full" mt={2} gap={5}>
                <Heading as="h2" size="md" fontWeight="medium">
                  {t('subscriptions:subheading_contact')}
                </Heading>
                <Box h="1px" w="full" bg="gray.300" mt={1}></Box>
              </HStack>
              <HStack w="full" mt={2}>
                <SubscriptionFormField
                  name="email"
                  label={t('subscriptions:label_email')}
                  error={errors.email}
                />
                <SubscriptionFormField
                  name="phone"
                  label={t('subscriptions:label_phone')}
                  error={errors.phone}
                  isRequired={false}
                />
              </HStack>
              <HStack w="full" mt={2} gap={5}>
                <Heading as="h2" size="md" fontWeight="medium">
                  {t('subscriptions:subheading_address')}
                </Heading>
                <Box h="1px" w="full" bg="gray.300" mt={1}></Box>
              </HStack>
              <SubscriptionFormField
                name="address"
                label={t('subscriptions:label_address')}
                error={errors.address}
              />
              <HStack w="full" mt={2}>
                <SubscriptionFormField
                  name="city"
                  label={t('subscriptions:label_city')}
                  error={errors.city}
                />
                <SubscriptionFormField
                  name="postcode"
                  label={t('subscriptions:label_postal_code')}
                  error={errors.postcode}
                />
              </HStack>
              <SubscriptionFormField
                name="country"
                label={t('subscriptions:label_country')}
                error={errors.country}
              />
              <Field name="newsletter">
                {({ field }: FieldProps) => (
                  <FormControl display="flex" alignItems="center" gap={4} p={1}>
                    <Switch
                      colorScheme="switch"
                      id="news-letter"
                      size="md"
                      isChecked={field.value}
                      {...field}
                    />
                    <FormLabel htmlFor="news-letter" mb={0}>
                      {t('subscriptions:label_newsletter')}
                    </FormLabel>
                  </FormControl>
                )}
              </Field>
              {Object.keys(errors).length !== 0 && (
                <Flex w="full" mt={2}>
                  <Alert status="error" borderRadius="md">
                    <AlertIcon />
                    {t('subscriptions:msg_validation_errors')}
                  </Alert>
                </Flex>
              )}
              <HStack w="full" justifyContent="flex-end">
                <Button variant="text" onClick={handleCancelBillingInformation}>
                  {t('globals:btn_cancel')}
                </Button>
                {selectedSubscription ? (
                  <Button
                    id="create-subscription"
                    isDisabled={Object.keys(errors).length !== 0}
                    type="submit"
                    isLoading={isSubmitting || isLoadingTransactionUrl}
                    _disabled={{ opacity: 0.5 }}
                  >
                    {t('subscriptions:btn_pay', {
                      annual: t(
                        `subscriptions:options_annual_pricing.${selectedSubscription.type}`,
                      ),
                    })}
                  </Button>
                ) : (
                  <Button
                    id="update-subscription"
                    isDisabled={Object.keys(errors).length !== 0}
                    type="submit"
                    isLoading={isSubmitting}
                    _disabled={{ opacity: 0.5 }}
                  >
                    {t('globals:btn_save')}
                  </Button>
                )}
              </HStack>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};
