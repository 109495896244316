import { List } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { NoItemsPlaceholder } from '../../../common';

import { ChecklistsListItem } from './ChecklistsListItem';
import { ChecklistDto } from '@models';

type ChecklistsListProps = {
  checklists: ChecklistDto[];
  selected: ChecklistDto | null;
  selectionHandler: (checklist: ChecklistDto) => void;
  refetchChecklists: () => void;
};

export function ChecklistsList({
  checklists,
  selected,
  selectionHandler,
  refetchChecklists,
}: ChecklistsListProps) {
  const { t } = useTranslation();

  return (
    <List data-testid="products" spacing={{ sm: 2, md: 3 }}>
      {checklists!.length === 0 && (
        <NoItemsPlaceholder text={t('checklists:placeholder_no_checklists')} />
      )}

      {checklists?.map((checklist) => (
        <ChecklistsListItem
          key={checklist.id}
          checklist={checklist}
          selected={selected?.id === checklist.id}
          onSelect={() => selectionHandler(checklist)}
          refetchChecklists={refetchChecklists}
        />
      ))}
    </List>
  );
}
