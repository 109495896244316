/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Workspace Warden
 * OpenAPI spec version: 0.0.1
 */

export type GetPaymentStatusDtoStatus =
  (typeof GetPaymentStatusDtoStatus)[keyof typeof GetPaymentStatusDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPaymentStatusDtoStatus = {
  OPEN: 'OPEN',
  PAID: 'PAID',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED',
  EXPIRED: 'EXPIRED',
} as const;
