import { Flex } from '@chakra-ui/react';

type Props = {
  children: JSX.Element | JSX.Element[];
};

export function BackgroundContainer({ children }: Props) {
  return (
    <Flex
      w="full"
      h="full"
      p={0}
      bgImage="url(/background.png)"
      backgroundPosition="center"
      backgroundSize="cover"
      justifyContent="center"
      alignItems="center"
      zIndex={1}
      flexDir={{ base: 'column', md: 'row' }}
    >
      <Flex position="fixed" w="full" h="full" opacity={0.96} zIndex={1}></Flex>
      {children}
    </Flex>
  );
}
