import { HStack, List, Spinner, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import { NoItemsPlaceholder } from '../../../common';

import { ProductsListItem } from './ProductsListItem';
import { ProductSearchResultDto } from '@models';
import { useEffect } from 'react';
import { SelectedProduct } from '../../models';

export type ProducstsListProps = {
  products: ProductSearchResultDto[];
  selected: SelectedProduct | null;
  selectionHandler: (product: ProductSearchResultDto) => void;
  handleFetchNextPage: () => void;
  isFetching: boolean;
  hasNextPage: boolean | undefined;
};

export function ProductsList({
  products,
  selected,
  selectionHandler,
  handleFetchNextPage,
  isFetching,
  hasNextPage,
}: ProducstsListProps) {
  const { t } = useTranslation();

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      handleFetchNextPage();
    }
  }, [inView]);

  return (
    <List h="full" spacing={{ base: 2, lg: 3 }} data-testid="products">
      {products!.length === 0 && (
        <NoItemsPlaceholder text={t('products:placeholder_no_products')} />
      )}

      {products?.map((item) => (
        <ProductsListItem
          key={`${item.id}_${item.kind}`}
          productSearchResult={item}
          selected={selected?.id === item.id && selected.kind === item.kind}
          onSelect={() => selectionHandler(item)}
        />
      ))}

      {products.length && (
        <HStack
          id="next-page"
          w="full"
          justifyContent="center"
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            handleFetchNextPage();
          }}
          gap={5}
          pt={5}
        >
          {isFetching && <Spinner />}
          <Text>
            {isFetching || hasNextPage
              ? `${t('products:infinite_scroll_more')}`
              : hasNextPage
                ? `${t('products:infinite_scroll_new')}`
                : ``}
          </Text>
        </HStack>
      )}
    </List>
  );
}
