import {
  Button,
  Flex,
  FormControl,
  HStack,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFileCsv } from 'react-icons/fa';
import { IoMdAddCircle } from 'react-icons/io';

import { notifyUser } from '../../../common';
import { useChecklistControllerUploadCsv } from '@hooks';
import { ChecklistDto } from '@models';

type Props = {
  selectionHandler: (checklist: ChecklistDto) => void;
  refetchChecklists: () => void;
};

export function ChecklistUploadModal({
  selectionHandler,
  refetchChecklists,
}: Props) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [checklistName, setChecklistName] = useState('');
  const [file, setFile] = useState<File | null>(null);

  const { mutate: uploadChecklistCsv } = useChecklistControllerUploadCsv({
    mutation: {
      onSuccess: (data) => {
        notifyUser(
          t('checklists:toast_success_import_csv', { name: data.data.name }),
          false,
        );
        setChecklistName('');
        selectionHandler({
          ...data.data,
        });
        onClose();
      },
      onError: () => {
        notifyUser(t('checklists:toast_error_import_csv'), true);
      },
      onSettled: () => {
        refetchChecklists();
      },
    },
  });

  const handleFileUpload = async () => {
    if (!file) return;
    const formData = {
      name: checklistName || file.name,
      file: file,
    };

    uploadChecklistCsv({ data: formData });
  };

  return (
    <>
      <Button
        w="fit-content"
        variant="solid"
        onClick={onOpen}
        leftIcon={<FaFileCsv size={18} />}
      >
        {t('checklists:btn_import')}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader pb={0}>
            {t('checklists:modal_header_import_checklist')}
          </ModalHeader>
          <ModalBody pt={0} w="full" display="flex" flexDir="column" gap={5}>
            <Text>{t('checklists:modal_body_import_checklist')}</Text>

            <HStack>
              <Flex w="full" display="column">
                <FormControl isInvalid={false}>
                  <InputGroup display="flex" gap={3}>
                    <Input
                      type="file"
                      ref={fileInputRef}
                      display="none"
                      placeholder="Importeer CSV"
                      bg="brand.gray"
                      borderColor="brand.white"
                      _hover={{ borderColor: 'brand.white' }}
                      onChange={(e) => {
                        if (e.target.files && e.target.files.length > 0) {
                          setFile(e.target.files[0]);
                          setChecklistName(
                            e.target.files[0].name.replace('.csv', ''),
                          );
                        }
                      }}
                    />
                    <VStack w="full" alignItems="flex-start">
                      <Text ml={2} mb={1}>
                        {t('checklists:label_edit_checklist')}
                      </Text>

                      <HStack w="full">
                        <Input
                          flex={1}
                          type="text"
                          bg="brand.gray"
                          borderColor="brand.white"
                          value={checklistName}
                          isDisabled={!checklistName}
                          onChange={(e) => {
                            setChecklistName(e.target.value);
                          }}
                        />
                        <Button
                          variant="solid"
                          fontWeight="normal"
                          w="fit-content"
                          justifyContent="flex-start"
                          leftIcon={<IoMdAddCircle size={20} />}
                          onClick={() => {
                            fileInputRef.current &&
                              fileInputRef.current.click();
                          }}
                        >
                          CSV
                        </Button>
                      </HStack>
                    </VStack>
                  </InputGroup>
                </FormControl>
              </Flex>
            </HStack>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="text"
              onClick={() => {
                setFile(null);
                setChecklistName('');
                onClose();
              }}
            >
              {t('globals:btn_cancel')}
            </Button>
            <Button
              id="upload-csv"
              isDisabled={!checklistName}
              mr={3}
              onClick={handleFileUpload}
            >
              {t('globals:btn_save')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
