import { Flex } from '@chakra-ui/react';
import { usePaymentControllerGetPaymentStatus } from '@hooks';
import { GetPaymentStatusDtoStatus } from '@models';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { InfoCard, LoadingPlaceholder } from 'src/modules';

export function PaymentStatusPage() {
  const { t } = useTranslation();
  const { orderId } = useParams();

  const { data: paymentStatus, isLoading } =
    usePaymentControllerGetPaymentStatus(orderId!);

  if (isLoading)
    return (
      <Flex
        w="full"
        h="full"
        zIndex={0}
        bgImage="url(/background.png)"
        bgSize="cover"
        bgPosition="center"
        overflow="hidden"
        justifyContent="center"
        alignItems="center"
        flexDir={{ base: 'column', lg: 'row' }}
        p={{ base: 4, lg: 12 }}
      >
        <LoadingPlaceholder text={t('subscriptions:loading_payment_status')} />
      </Flex>
    );

  if (paymentStatus?.data.status === GetPaymentStatusDtoStatus.PAID)
    return (
      <Flex
        w="full"
        h="full"
        zIndex={0}
        bgImage="url(/background.png)"
        bgSize="cover"
        bgPosition="center"
        overflow="hidden"
        justifyContent="center"
        alignItems="center"
        flexDir={{ base: 'column', lg: 'row' }}
        p={{ base: 4, lg: 12 }}
      >
        <InfoCard
          type={'success'}
          subtitle={t('subscriptions:subtitle_payment_success')}
          title={t('subscriptions:title_payment_success')}
          message={t('subscriptions:msg_payment_success')}
          linkLabel={t('subscriptions:label_link_payment_success')}
          link={'/products'}
        />
      </Flex>
    );

  return (
    <Flex
      w="full"
      h="full"
      zIndex={0}
      bgImage="url(/background.png)"
      bgSize="cover"
      bgPosition="center"
      overflow="hidden"
      justifyContent="center"
      alignItems="center"
      flexDir={{ base: 'column', lg: 'row' }}
      p={{ base: 4, lg: 12 }}
    >
      <InfoCard
        type={'error'}
        subtitle={t('subscriptions:subtitle_payment_error')}
        title={t('subscriptions:title_payment_error')}
        message={t('subscriptions:msg_payment_error')}
        linkLabel={t('subscriptions:label_link_payment_error')}
        link={'/settings/subscription'}
      />
    </Flex>
  );
}
