import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './modules/auth/hooks/';
import { ErrorFallback, SentryRoutes, theme } from './modules/index.ts';
import { ErrorBoundary } from 'react-error-boundary';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Route,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import { ChakraProvider } from '@chakra-ui/react';
import { routerConfig } from './pages/index.tsx';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import './i18n.ts';
import React from 'react';

Sentry.init({
  dsn: 'https://d876da3d4ad1faf7662e17f91d471784@o1258509.ingest.us.sentry.io/4506937940574208',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],

  tracesSampleRate: 1.0,

  tracePropagationTargets: [
    'localhost',
    /^https:\/\/app\.workspacewarden\.com/,
  ],
});

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const googleAnalyticsId = import.meta.env.VITE_GOOGLE_ANALYTICS_ID;
const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;

if (googleAnalyticsId) ReactGA.initialize(googleAnalyticsId);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={true} />
            <HelmetProvider>
              <GoogleOAuthProvider clientId={clientId}>
                <AuthProvider>
                  <SentryRoutes>
                    {routerConfig.map((route) => (
                      <Route key={route.path} {...route} />
                    ))}
                  </SentryRoutes>
                </AuthProvider>
              </GoogleOAuthProvider>
            </HelmetProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </ChakraProvider>
  </StrictMode>,
);
