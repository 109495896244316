import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '../hooks';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export function RequireAuth({ children }: Props) {
  const { isAuthenticated, isLoading } = useAuth();
  const { pathname } = useLocation();

  if (!isAuthenticated && !isLoading) {
    return (
      <Navigate
        replace
        to={{
          pathname: '/login',
          search: `redirect=${pathname}`,
        }}
      />
    );
  }

  return children;
}
