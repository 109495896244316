import { Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { LoadingPlaceholder, notifyUser } from '../../common';

import { ChangeImpersonator } from './ChangeImpersonator';
import { SettingsContent } from './SettingsContent';
import {
  getTenantControllerGetImpersonatorQueryKey,
  useTenantControllerGetImpersonator,
  useTenantControllerSetImpersonator,
} from '@hooks';
import { useQueryClient } from '@tanstack/react-query';

export function ImpersonatorSettings() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data: impersonator, isLoading: isLoadingImpersonator } =
    useTenantControllerGetImpersonator();

  const { mutate: updateImpersonator } = useTenantControllerSetImpersonator({
    mutation: {
      onSuccess: () =>
        notifyUser(t('settings:toast_success_update_impersonator'), false),
      onError: () => {
        notifyUser(t('settings:toast_error_update_impersonator'), true);
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: getTenantControllerGetImpersonatorQueryKey(),
        });
      },
    },
  });

  const handleChangeImpersonator = (email: string) => {
    updateImpersonator({
      data: {
        impersonator: email,
      },
    });
  };

  return (
    <SettingsContent
      title={t('settings:title_impersonator')}
      description={t('settings:description_impersonator')}
    >
      {isLoadingImpersonator ? (
        <LoadingPlaceholder text={t('settings:loading_impersonator')} />
      ) : (
        <VStack alignItems="flex-start">
          <Text>{t('settings:label_current_impersonator')}</Text>
          <Text fontWeight="medium">{impersonator?.data.impersonator}</Text>
        </VStack>
      )}
      {impersonator && (
        <ChangeImpersonator
          updateHandler={handleChangeImpersonator}
          impersonator={impersonator.data.impersonator}
        />
      )}
    </SettingsContent>
  );
}
