import {
  Box,
  Image,
  useDisclosure,
  HStack,
  Divider,
  Text,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import { formatDistance } from 'date-fns';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaBars,
  FaCog,
  FaHome,
  FaListAlt,
  FaSignOutAlt,
  FaSyncAlt,
} from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';

import { MobileSearch } from '../desktop';

import { HeaderButton } from './HeaderButton';
import { HeaderContainer } from './HeaderContainer';
import { HeaderLink } from './HeaderLink';
import { HeaderMenuButton } from './HeaderMenuButton';
import { HeaderMenuContainer } from './HeaderMenuContainer';
import {
  useSyncControllerGetSyncInfo,
  useSyncControllerStartSync,
} from '@hooks';
import { useAuth } from 'src/modules/auth';
import { notifyUser } from 'src/modules/common/notifyUser';
import { nl, enUS } from 'date-fns/locale';

export function Header() {
  const { t } = useTranslation();
  const { isOpen, onClose, onToggle } = useDisclosure();
  const {
    isOpen: isModalOpen,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const { logout } = useAuth();

  const { data: time, refetch: refetchSyncInfo } =
    useSyncControllerGetSyncInfo();

  const syncModalOkayButtonRef = useRef(null);
  const syncModalCancelButtonRef = useRef(null);

  const locale = navigator.language === 'nl' ? nl : enUS;

  function canSync() {
    return time?.data.canSync;
  }

  const { mutate: synchronize, isPending: isSynchronizing } =
    useSyncControllerStartSync({
      mutation: {
        onSuccess: () => {
          notifyUser(t('settings:toast_success_start_sync'), false);
        },
        onError: () => {
          notifyUser(t('settings:toast_error_start_sync'), true);
        },
        onSettled: () => {
          refetchSyncInfo();
        },
      },
    });

  function getTimeSinceLastSyncString() {
    const timestamp = time?.data.timestamp;
    if (!timestamp) return;

    return formatDistance(timestamp, new Date(), { locale });
  }

  function getTimeUntilNextSyncString() {
    const nextTimestamp = time?.data.nextTimestamp;

    if (!nextTimestamp) return;

    return formatDistance(new Date(), nextTimestamp, {
      locale,
    });
  }

  function onSync() {
    synchronize();
    onClose();
  }

  function SyncModal() {
    return (
      <Modal
        isCentered
        isOpen={isModalOpen}
        onClose={onCloseModal}
        initialFocusRef={
          canSync() ? syncModalOkayButtonRef : syncModalCancelButtonRef
        }
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('sidebar:modal_header_sync')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack gap={4} alignItems="start">
              <Text>
                {t('sidebar:modal_body_sync', {
                  time: getTimeSinceLastSyncString(),
                })}
              </Text>
              {canSync() ? (
                <Text>{t('sidebar:modal_body_can_sync')}</Text>
              ) : (
                <Text>
                  {t('sidebar:modal_body_cannot_sync', {
                    time: getTimeUntilNextSyncString(),
                  })}
                </Text>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="text"
              onClick={onCloseModal}
              ref={syncModalCancelButtonRef}
            >
              {t('globals:btn_cancel')}
            </Button>
            <Button
              id="sync-products-mobile"
              isDisabled={!canSync()}
              variant="solid"
              mr={2}
              onClick={onSync}
              ref={syncModalOkayButtonRef}
            >
              {t('globals:btn_continue')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Box zIndex={3} w="full">
      <HeaderContainer>
        <Image src="/icon.svg" h="32px" />
        <HStack>
          <MobileSearch isOpen={false} />
          <HeaderMenuButton
            icon={FaSyncAlt}
            onClick={onOpenModal}
            hasAnimation={isSynchronizing}
          />
          <SyncModal />
          <HeaderMenuButton
            icon={isOpen ? IoClose : FaBars}
            onClick={onToggle}
          />
        </HStack>
      </HeaderContainer>

      {isOpen && (
        <HeaderMenuContainer>
          <HeaderLink
            icon={FaHome}
            onClose={onClose}
            name={t('sidebar:btn_products')}
            routeUrl="/products"
          />
          <Divider />
          <HeaderLink
            icon={FaListAlt}
            onClose={onClose}
            name={t('sidebar:btn_checklists')}
            routeUrl="/checklists"
          />
          <Divider />
          <HeaderLink
            icon={FaCog}
            onClose={onClose}
            name={t('sidebar:btn_settings')}
            routeUrl="/settings"
          />
          <Divider />
          <HeaderButton
            buttonId="logout-user-mobile"
            icon={FaSignOutAlt}
            name={t('sidebar:btn_logout')}
            isMobile
            onClick={() => logout()}
          />
        </HeaderMenuContainer>
      )}
    </Box>
  );
}
