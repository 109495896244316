import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaUserPlus } from 'react-icons/fa';
import { UserSearchResultDto } from '@models';
import { useSearchControllerSearchUsers } from '@hooks';
import { useDebounce } from 'use-debounce';

type Props = {
  handler: (member: string) => void;
};

export function AddMember({ handler }: Props) {
  const { t } = useTranslation();
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [query, setQuery] = useState<string | null>(null);
  const [debouncedQuery] = useDebounce(query, 250);
  const [items, setItems] = useState<UserSearchResultDto[]>([]);
  const [user, setUser] = useState<UserSearchResultDto | null>(null);

  function handleAddMember(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    function setUserData(member: string) {
      setEmailIsInvalid(false);
      setIsProcessing(true);
      handler(member);
      setUser(null);
      setIsProcessing(false);
      setQuery('');
    }

    if (user) {
      setUserData(user.email);
      return;
    }

    if (!query) return;

    if (!emailRegex.test(query)) {
      setEmailIsInvalid(true);
      return;
    }

    setUserData(query);
  }

  const {
    data: results,
    isFetching,
    isLoading,
  } = useSearchControllerSearchUsers(
    debouncedQuery!,
    {},
    {
      query: {
        enabled: !!debouncedQuery,
      },
    },
  );

  useEffect(() => {
    if (!isFetching) setItems(results?.data || []);
  }, [results, isFetching]);

  return (
    <VStack w="full" alignItems="start" mt="auto" gap={{ base: 0, md: 1 }}>
      <Text ml={2} fontSize={{ base: 'sm', md: 'md' }}>
        {t('products:label_link_member')}
      </Text>
      <HStack w="full">
        <form
          style={{ display: 'flex', width: '100%' }}
          onSubmit={handleAddMember}
        >
          <Box display="flex" flex={1} m={1} gap={2}>
            <FormControl isInvalid={emailIsInvalid}>
              <Select
                size="sm"
                menuPlacement="top"
                menuPosition="fixed"
                options={items}
                formatOptionLabel={(data, meta) =>
                  meta.context === 'menu' ? (
                    <VStack alignItems="start" gap={0} spacing={0}>
                      <Box
                        color="brand.primary"
                        dangerouslySetInnerHTML={{
                          __html: data.highlightedName!,
                        }}
                      />
                      <Box
                        color="gray.400"
                        dangerouslySetInnerHTML={{
                          __html: data.highlightedEmail!,
                        }}
                      />
                    </VStack>
                  ) : (
                    <div>
                      <Text color="brand.primary">
                        {user
                          ? data?.email
                          : t('products:placeholder_search_member')}
                      </Text>
                    </div>
                  )
                }
                isLoading={isFetching || isLoading}
                inputValue={query ? query : undefined}
                onInputChange={(value, action) => {
                  if (
                    action?.action !== 'input-blur' &&
                    action?.action !== 'menu-close'
                  ) {
                    setQuery(null);
                    setUser(null);
                    setQuery(value);
                  }
                }}
                onChange={(item) => {
                  if (!item) return;
                  setUser(null);
                  setQuery(null);
                  handler(item.email);
                }}
                filterOption={null}
                placeholder={t('products:placeholder_search_member')}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                onBlur={(e) => {
                  e.preventDefault();
                }}
                selectedOptionColor="transparent"
                chakraStyles={{
                  menu: (base, { selectProps }) => ({
                    ...base,
                    display:
                      selectProps.options.length < 1 ? 'none' : 'inline-flex',
                    top: 'auto',
                    bottom: '100%',
                    marginBottom: '35px',
                  }),
                  control: (base) => ({
                    ...base,
                    bg: 'brand.gray',
                    color: 'brand.primary',
                    borderColor: 'brand.gray',
                    borderRadius: 'lg',
                    _hover: {
                      cursor: 'text',
                    },
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: 'brand.secondary',
                  }),
                }}
              />
              {emailIsInvalid && (
                <FormErrorMessage>
                  {t('products:invalid_email')}
                </FormErrorMessage>
              )}
            </FormControl>
            <Button
              size="sm"
              border="none"
              isLoading={isProcessing}
              borderRadius="md"
              fontSize="sm"
              type="submit"
              isDisabled={!query || isProcessing}
            >
              <Icon as={FaUserPlus} />
            </Button>
          </Box>
        </form>
      </HStack>
    </VStack>
  );
}
