/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Workspace Warden
 * OpenAPI spec version: 0.0.1
 */

export type UpdateMemberRoleDtoRole =
  (typeof UpdateMemberRoleDtoRole)[keyof typeof UpdateMemberRoleDtoRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateMemberRoleDtoRole = {
  organizer: 'organizer',
  fileOrganizer: 'fileOrganizer',
  writer: 'writer',
  commenter: 'commenter',
  reader: 'reader',
  owner: 'owner',
  freeBusyReader: 'freeBusyReader',
} as const;
