import { Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ProductMemberListItem } from './ProductMembersListItem';
import { ProductDetailsDto } from '@models';

type ProductMemberListProps = {
  product: ProductDetailsDto;
  refetch: () => void;
};

export function ProductMembersList({
  product,
  refetch,
}: ProductMemberListProps) {
  const { t } = useTranslation();

  return (
    <VStack h="full" alignItems="space-between" mb={5} overflow="auto" gap={0}>
      {product.members?.map((member) => (
        <ProductMemberListItem
          key={member.email}
          product={product}
          member={member}
          refetch={refetch}
        />
      ))}

      {(!product.members || product.members.length === 0) && (
        <Text my={4} textAlign="center">
          {t('products:placeholder_no_members')}
        </Text>
      )}
    </VStack>
  );
}
