import {
  Button,
  Divider,
  Image,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaCog,
  FaHome,
  FaSignOutAlt,
  FaSyncAlt,
  FaListAlt,
  FaSearch,
} from 'react-icons/fa';

import { useAuth } from '../../../../auth';
import { NavigationLink } from '../NavigationLink';

import { SidebarButton } from './SidebarButton';
import { SidebarContainer } from './SidebarContainer';
import {
  useSyncControllerGetSyncInfo,
  useSyncControllerStartSync,
} from '@hooks';
import { formatDistance } from 'date-fns';
import { nl, enUS } from 'date-fns/locale';
import { notifyUser } from 'src/modules/common/notifyUser';

export function Sidebar() {
  const { t } = useTranslation();
  const { isOpen: isModalOpen, onOpen, onClose } = useDisclosure();

  const { logout } = useAuth();

  const { data: time, refetch: refetchSyncInfo } =
    useSyncControllerGetSyncInfo();

  const syncModalOkayButtonRef = useRef(null);
  const syncModalCancelButtonRef = useRef(null);

  const locale = navigator.language === 'nl' ? nl : enUS;

  function canSync() {
    return time?.data.canSync;
  }

  const { mutate: synchronize, isPending: isSynchronizing } =
    useSyncControllerStartSync({
      mutation: {
        onSuccess: () => {
          notifyUser(t('settings:toast_success_start_sync'), false);
        },
        onError: () => {
          notifyUser(t('settings:toast_error_start_sync'), true);
        },
        onSettled: () => {
          refetchSyncInfo();
        },
      },
    });

  function getTimeSinceLastSyncString() {
    const timestamp = time?.data.timestamp;
    if (!timestamp) return;

    return formatDistance(timestamp, new Date(), { locale });
  }

  function getTimeUntilNextSyncString() {
    const nextTimestamp = time?.data.nextTimestamp;

    if (!nextTimestamp) return;

    return formatDistance(new Date(), nextTimestamp, {
      locale,
    });
  }

  function onSync() {
    synchronize();
    onClose();
  }

  function SyncModal() {
    return (
      <Modal
        isCentered
        isOpen={isModalOpen}
        onClose={onClose}
        initialFocusRef={
          canSync() ? syncModalOkayButtonRef : syncModalCancelButtonRef
        }
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('sidebar:modal_header_sync')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack gap={4} alignItems="start">
              <Text>
                {t('sidebar:modal_body_sync', {
                  time: getTimeSinceLastSyncString(),
                })}
              </Text>
              {canSync() ? (
                <Text>{t('sidebar:modal_body_can_sync')}</Text>
              ) : (
                <Text>
                  {t('sidebar:modal_body_cannot_sync', {
                    time: getTimeUntilNextSyncString(),
                  })}
                </Text>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="text"
              onClick={onClose}
              ref={syncModalCancelButtonRef}
            >
              {t('globals:btn_cancel')}
            </Button>
            <Button
              id="sync-products"
              isDisabled={!canSync()}
              variant="solid"
              mr={2}
              onClick={onSync}
              ref={syncModalOkayButtonRef}
            >
              {t('globals:btn_continue')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <SidebarContainer>
      <VStack alignItems="flex-start" gap={5}>
        <Image
          src={'/icon.svg'}
          h={{ base: '32px', xl: '48px' }}
          alt="Workspace Warden logo"
          mt={5}
          mb={10}
          alignSelf="center"
        />
        <NavigationLink
          name={t('sidebar:btn_search')}
          routeUrl="/search"
          icon={FaSearch}
        />
        <NavigationLink
          name={t('sidebar:btn_products')}
          routeUrl="/products"
          icon={FaHome}
        />
        <NavigationLink
          name={t('sidebar:btn_checklists')}
          routeUrl="/checklists"
          icon={FaListAlt}
        />
      </VStack>
      <VStack alignItems="flex-start" gap={5}>
        <SidebarButton
          onClick={onOpen}
          icon={FaSyncAlt}
          name={t('sidebar:btn_synchronize')}
          hasAnimation={isSynchronizing}
        />
        <SyncModal />
        <NavigationLink
          name={t('sidebar:btn_settings')}
          routeUrl="/settings"
          icon={FaCog}
        />
        <Divider />
        <SidebarButton
          buttonId="logout-user"
          onClick={() => logout()}
          icon={FaSignOutAlt}
          name={t('sidebar:btn_logout')}
        />
      </VStack>
    </SidebarContainer>
  );
}
