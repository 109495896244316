import { Flex, Button, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useSubscriptionControllerGetMyCustomer } from '@hooks';

export function SubscriptionBanner() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: customer } = useSubscriptionControllerGetMyCustomer();

  return (
    <Flex
      position="absolute"
      w="full"
      bg="brand.primary"
      justifyContent="center"
      alignItems="center"
      color="brand.white"
      h={14}
      top={0}
      zIndex={2}
      gap={{ base: 4, md: 5 }}
    >
      <Text fontSize="sm">
        {t('subscriptions:placeholder_banner_subscription', {
          days: customer?.data.daysRemaining,
        })}
      </Text>
      <Button
        id="navigate-subscription"
        variant="solid"
        bg="brand.white"
        size="sm"
        fontWeight="medium"
        color="brand.primary"
        _hover={{ bg: 'brand.white' }}
        onClick={() => {
          navigate('/settings/subscription');
        }}
      >
        {t('subscriptions:btn_banner_subscription')}
      </Button>
    </Flex>
  );
}
