import { Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ProductChecklistsListItem } from './ProductChecklistsListItem';
import { ProductChecklistDto } from '@models';

type ProductLinkedItemProps = {
  checklists: ProductChecklistDto[];
  unlinkHandler(checklistId: string): void;
};

export function ProductChecklistsList({
  checklists,
  unlinkHandler,
}: ProductLinkedItemProps) {
  const { t } = useTranslation();

  return (
    <VStack
      h="full"
      alignItems="space-between"
      mt={3}
      mb={5}
      overflow="auto"
      gap={6}
    >
      {checklists.map((checklist) => (
        <ProductChecklistsListItem
          key={checklist.id}
          checklist={checklist}
          unlinkHandler={unlinkHandler}
        />
      ))}

      {(!checklists || checklists.length === 0) && (
        <Text size={{ base: 'sm', md: 'md' }} my={4} textAlign="center">
          {t('products:placeholder_no_checklists')}
        </Text>
      )}
    </VStack>
  );
}
