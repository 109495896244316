import { Button, HStack, Icon, keyframes } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { IconType } from 'react-icons';

type Props = {
  icon: IconType;
  onClick: () => void;
  hasAnimation?: boolean;
};

export function HeaderMenuButton({
  icon,
  onClick,
  hasAnimation = false,
}: Props) {
  const animationKeyframes = keyframes`
  0% { transform:  rotate(0);}
  100% { transform:  rotate(360deg); }
`;

  const animation = `${animationKeyframes} 1s linear infinite`;

  return (
    <Button
      w="full"
      variant="ghost"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      onClick={onClick}
      color="brand.secondary"
      _hover={{ color: 'brand.primary' }}
      p={4}
      py={6}
      gap={6}
    >
      <HStack as={motion.div} animation={hasAnimation ? animation : 'none'}>
        <Icon as={icon} boxSize={4} />
      </HStack>
    </Button>
  );
}
