import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  notifyUser,
  CustomTabs,
  CustomTabList,
  CustomTab,
  CustomTabPanels,
  CustomTabPanel,
  LoadingPlaceholder,
  LinkProduct,
  AddMember,
} from '../../../common';
import {
  useChecklistControllerAddMembers,
  useChecklistControllerConnectProduct,
  useChecklistControllerDeleteConnction,
  useChecklistControllerDeleteMembers,
} from '@hooks';

import { ChecklistMembersList } from './ChecklistMembersList';
import { ChecklistProductsList } from './ChecklistProductsList';
import {
  ChecklistDto,
  ConnectChecklistProductDto,
  DeleteChecklistProductDto,
} from '@models';

type Props = {
  checklist: ChecklistDto;
  /** Invalidate the parent checklist query result and refetch */
  refetch: () => void;
};

export function ChecklistDetails({ checklist, refetch }: Props) {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  const { mutate: addMember, isPending: isLoadingAddMember } =
    useChecklistControllerAddMembers({
      mutation: {
        onSuccess: () => {
          notifyUser(t('checklists:toast_success_add_member'), false);
        },
        onError: () => {
          notifyUser(t('checklists:toast_failed_add_member'), true);
        },
        onSettled: () => {
          refetch();
        },
      },
    });

  function onAddMember(email: string) {
    addMember({ id: checklist.id, data: { members: [email] } });
  }

  const { mutate: removeMember } = useChecklistControllerDeleteMembers({
    mutation: {
      onSuccess: () => {
        notifyUser(t('checklists:toast_success_remove_member'), false);
      },
      onError: () => {
        notifyUser(t('checklists:toast_failed_remove_member'), true);
      },
      onSettled: () => {
        refetch();
      },
    },
  });

  function onDeleteMember(member: string) {
    removeMember({ id: checklist.id, data: { members: [member] } });
  }

  const { mutate: linkProduct, isPending: isLoadingLinkProduct } =
    useChecklistControllerConnectProduct({
      mutation: {
        onSuccess: (data) => {
          console.log({ data });
          notifyUser(t('checklists:toast_success_link_product'), false);
        },
        onError: () => {
          notifyUser(t('checklists:toast_error_link_product'), true);
        },
        onSettled: () => {
          refetch();
        },
      },
    });

  function onLinkProduct(product: ConnectChecklistProductDto) {
    linkProduct({ id: checklist.id, data: { products: [product] } });
  }

  const { mutate: unlinkProduct } = useChecklistControllerDeleteConnction({
    mutation: {
      onSuccess: () => {
        notifyUser(t('checklists:toast_success_unlink_product'), false);
      },
      onError: () => {
        notifyUser(t('checklists:toast_error_unlink_product'), true);
      },
      onSettled: () => {
        refetch();
      },
    },
  });

  function onUnlinkProduct(product: DeleteChecklistProductDto) {
    unlinkProduct({
      id: checklist.id,
      data: { products: [product] },
    });
  }

  return (
    <CustomTabs tabIndex={tabIndex} tabsChangeHandler={handleTabChange}>
      <CustomTabList isDetails>
        <CustomTab name={t('tabs:tab_members')} />
        <CustomTab name={t('tabs:tab_products')} />
      </CustomTabList>
      <CustomTabPanels>
        <CustomTabPanel>
          {!isLoadingAddMember && (
            <ChecklistMembersList
              isReadonly={checklist?.isReadOnly}
              members={checklist.members || []}
              isLoading={true}
              deleteHandler={onDeleteMember}
            />
          )}
          {!checklist.isReadOnly && <AddMember handler={onAddMember} />}
        </CustomTabPanel>
        <CustomTabPanel>
          {isLoadingLinkProduct && (
            <LoadingPlaceholder text={t('checklists:loading_products')} />
          )}
          {!isLoadingLinkProduct && (
            <ChecklistProductsList
              products={checklist.products}
              unlinkHandler={onUnlinkProduct}
            />
          )}
          <LinkProduct
            handler={onLinkProduct}
            checklistIds={checklist.products.map((product) => product.id)}
          />
        </CustomTabPanel>
      </CustomTabPanels>
    </CustomTabs>
  );
}
