import { Flex, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  notifyUser,
  CustomTabs,
  CustomTabList,
  CustomTab,
  CustomTabPanels,
  CustomTabPanel,
  LoadingPlaceholder,
  AddMember,
  LinkChecklist,
} from '../../../common';

import { ProductChecklistsList } from './ProductChecklistsList';
import { ProductMembersList } from './ProductMembersList';
import { ProductDetailsDto } from '@models';
import {
  useChecklistControllerConnectProduct,
  useChecklistControllerDeleteConnction,
  useProductControllerAddMember,
} from '@hooks';
import { ProductChecklistFromAccess } from './ProductChecklistFromAccess';

type Props = {
  refetch: () => void;
  product: ProductDetailsDto;
};

export function ProductDetails({ product, refetch }: Props) {
  const { t } = useTranslation();

  const { mutate: addMember, isPending: isLoadingAddMember } =
    useProductControllerAddMember({
      mutation: {
        onSuccess: () => {
          notifyUser(t('products:toast_success_add_member'));
        },
        onError: (error) => {
          if (error?.response?.status === 403) {
            notifyUser(t('products:toast_error_cannot_add_self'), true);
            return;
          }

          notifyUser(t('products:toast_error_add_member'), true);
        },
        onSettled: () => {
          refetch();
        },
      },
    });

  function onAddMember(memberEmail: string) {
    addMember({
      id: product.id,
      kind: product.kind,
      data: { memberEmail },
    });
  }

  const { mutate: linkChecklist, isPending: isLoadingLinkChecklist } =
    useChecklistControllerConnectProduct({
      mutation: {
        onSuccess: () => {
          notifyUser(t('checklists:toast_success_link_checklist'));
        },
        onError: () => {
          notifyUser(t('checklists:toast_error_link_checklist'), true);
        },
        onSettled: () => {
          refetch();
        },
      },
    });

  function onLinkChecklist(checklistId: string) {
    linkChecklist({
      id: checklistId,
      data: { products: [product] },
    });
  }

  const { mutate: unlinkChecklist } = useChecklistControllerDeleteConnction({
    mutation: {
      onSuccess: () => {
        notifyUser(t('products:toast_success_unlink_checklist'));
      },
      onError: () => {
        notifyUser(t('products:toast_error_unlink_checklist'), true);
      },
      onSettled: () => {
        refetch();
      },
    },
  });

  function onUnlinkChecklist(checklistId: string) {
    unlinkChecklist({
      id: checklistId,
      data: {
        products: [{ id: product.id, kind: product.kind }],
      },
    });
  }

  return (
    <CustomTabs>
      <CustomTabList isDetails>
        <CustomTab name={t('tabs:tab_members')} />
        <CustomTab name={t('tabs:tab_checklists')} />
      </CustomTabList>
      <CustomTabPanels>
        <CustomTabPanel>
          {isLoadingAddMember && (
            <LoadingPlaceholder text={t('products:loading_members')} />
          )}
          {!isLoadingAddMember && (
            <ProductMembersList product={product} refetch={refetch} />
          )}
          <AddMember key={product.id + product.kind} handler={onAddMember} />
        </CustomTabPanel>
        <CustomTabPanel>
          {isLoadingLinkChecklist && (
            <LoadingPlaceholder text={t('products:loading_checklists')} />
          )}
          {!isLoadingLinkChecklist && (
            <ProductChecklistsList
              checklists={product.checklists}
              unlinkHandler={onUnlinkChecklist}
            />
          )}
          <Flex
            mx={1}
            gap={0}
            mb={1}
            flexDir="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Text
              ml={1}
              mb={{ base: 1, md: 2 }}
              fontSize={{ base: 'sm', md: 'md' }}
            >
              {t('checklists:label_add_or_edit_checklist')}
            </Text>
            <HStack w="full">
              <LinkChecklist handler={onLinkChecklist} product={product} />
              <ProductChecklistFromAccess
                refetch={refetch}
                product={product}
                productChecklists={product.checklists}
              />
            </HStack>
          </Flex>
        </CustomTabPanel>
      </CustomTabPanels>
    </CustomTabs>
  );
}
