import { Button, HStack, Icon, Text } from '@chakra-ui/react';
import { IconType } from 'react-icons';

type Props = {
  name: string;
  onClick: () => void;
  isMobile?: boolean;
  icon?: IconType;
  buttonId?: string;
};

export function HeaderButton({
  name,
  onClick,
  isMobile = false,
  icon,
  buttonId,
}: Props) {
  return (
    <Button
      id={buttonId}
      variant="unstyled"
      fontWeight="normal"
      textAlign="left"
      h="56px"
      px={6}
      ml={isMobile ? 0 : 6}
      onClick={onClick}
    >
      <HStack gap={6}>
        {icon && <Icon as={icon} boxSize={4} />}
        <Text fontSize="sm" fontWeight="normal">
          {name}
        </Text>
      </HStack>
    </Button>
  );
}
