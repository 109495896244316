/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Workspace Warden
 * OpenAPI spec version: 0.0.1
 */

/**
 * Details about the subscription
 */
export type SubscriptionInfoDtoType =
  (typeof SubscriptionInfoDtoType)[keyof typeof SubscriptionInfoDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionInfoDtoType = {
  TRIAL: 'TRIAL',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
  CUSTOM: 'CUSTOM',
} as const;
