import { Flex, Spinner, Text } from '@chakra-ui/react';

type Props = {
  text: string;
};

export function LoadingPlaceholder({ text }: Props) {
  return (
    <Flex
      w="full"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      mt={12}
    >
      <Text fontSize={{ base: 'sm', md: 'md' }} mb="5">
        {text}
      </Text>
      <Spinner />
    </Flex>
  );
}
