import { HStack, Icon, ListItem } from '@chakra-ui/react';
import { MdClear } from 'react-icons/md';

type OrganizationalUnitListItemProps = {
  unit: string;
  deleteHandler: (unit: string) => void;
};

export function OrganizationalUnitsListItem({
  unit,
  deleteHandler,
}: OrganizationalUnitListItemProps) {
  return (
    <HStack w="full" my={3}>
      <ListItem
        display="flex"
        alignItems="center"
        bg="brand.gray"
        fontSize={{ base: 'sm', md: 'md' }}
        px={3}
        py={1}
        gap={3}
        borderRadius="md"
      >
        {unit}
        <Icon
          id="delete-org-unit"
          role="button"
          as={MdClear}
          onClick={() => {
            deleteHandler(unit);
          }}
        />
      </ListItem>
    </HStack>
  );
}
