import { HStack, Icon, ListItem } from '@chakra-ui/react';
import { MdClear } from 'react-icons/md';

type AuthorizedUsersListItemProps = {
  email: string;
  deleteHandler: (email: string) => void;
};

export function AuthorizedUsersListItem({
  email,
  deleteHandler,
}: AuthorizedUsersListItemProps) {
  return (
    <HStack w="full" my={3}>
      <ListItem
        display="flex"
        alignItems="center"
        bg="brand.gray"
        fontSize={{ base: 'sm', md: 'md' }}
        px={3}
        py={1}
        gap={3}
        borderRadius="md"
      >
        {email}{' '}
        <Icon
          id="delete-auth-user"
          role="button"
          as={MdClear}
          onClick={() => deleteHandler(email)}
        />
      </ListItem>
    </HStack>
  );
}
