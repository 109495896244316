import { Navigate, PathRouteProps } from 'react-router-dom';

import {
  RequireAuth,
  RequireSubscription,
  SubscriptionSettings,
} from '../modules';

import { ChecklistsPage } from './ChecklistsPage';
import { LoginPage } from './LoginPage';
import { PendingSyncPage } from './PendingSyncPage';
import { ProductsPage } from './ProductsPage';
import { SearchPage } from './SearchPage';
import { SettingsPage } from './SettingsPage';
import {
  AskAdminInstallationPage,
  AskAdminSubscriptionPage,
  MarketPlaceInstallationPage,
} from './subscription-status';
import { PaymentStatusPage } from './PaymentStatusPage';

export const routerConfig: PathRouteProps[] = [
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/search/*',
    element: (
      <RequireAuth>
        <RequireSubscription>
          <SearchPage />
        </RequireSubscription>
      </RequireAuth>
    ),
  },
  {
    path: '/products/*',
    element: (
      <RequireAuth>
        <RequireSubscription>
          <ProductsPage />
        </RequireSubscription>
      </RequireAuth>
    ),
  },
  {
    path: '/checklists/*',
    element: (
      <RequireAuth>
        <RequireSubscription>
          <ChecklistsPage />
        </RequireSubscription>
      </RequireAuth>
    ),
  },
  {
    path: '/settings/*',
    element: (
      <RequireAuth>
        <RequireSubscription>
          <SettingsPage />
        </RequireSubscription>
      </RequireAuth>
    ),
  },
  {
    path: '/subscription',
    element: <SubscriptionSettings />,
  },
  {
    path: '/payment/:orderId',
    element: (
      <RequireAuth>
        <PaymentStatusPage />
      </RequireAuth>
    ),
  },
  {
    path: '/ask-admin-installation',
    element: (
      <RequireAuth>
        <AskAdminInstallationPage />
      </RequireAuth>
    ),
  },
  {
    path: '/ask-admin-subscription',
    element: (
      <RequireAuth>
        <AskAdminSubscriptionPage />
      </RequireAuth>
    ),
  },
  {
    path: '/marketplace-installation',
    element: (
      <RequireAuth>
        <MarketPlaceInstallationPage />
      </RequireAuth>
    ),
  },
  {
    path: '/pending-sync',
    element: (
      <RequireAuth>
        <RequireSubscription>
          <PendingSyncPage />
        </RequireSubscription>
      </RequireAuth>
    ),
  },
  {
    path: '*',
    element: <Navigate replace to="/products" />,
  },
];
