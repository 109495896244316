import { TabList } from '@chakra-ui/react';

type Props = {
  children: React.ReactNode;
  isDetails?: boolean;
};

export function CustomTabList({ children, isDetails = false }: Props) {
  return (
    <TabList
      bg={isDetails ? 'brand.gray' : 'brand.white'}
      borderRadius="md"
      p="3px"
      border="none"
    >
      {children}
    </TabList>
  );
}
