import { Box, Flex, Text, VStack, Image, Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { GlobalSearchResultDto } from '@models';
import { getProductImage } from '@utils';
import { FaClipboardList } from 'react-icons/fa';

type Props = {
  result: GlobalSearchResultDto;
  selected: boolean;
  onMouseOver: () => void;
  onClick: () => void;
};

export const SearchResultCard = ({
  result,
  selected,
  onMouseOver,
  onClick,
}: Props) => {
  const { t } = useTranslation();

  const isChecklist = result.type === 'CHECKLIST';

  function getDisplayType(result: GlobalSearchResultDto) {
    return result.productKind?.toLocaleLowerCase();
  }

  return (
    <Box
      alignItems="flex-start"
      bg={selected ? 'brand.gradient' : 'brand.gray'}
      w="100%"
      style={{
        padding: '8px',
      }}
      borderRadius="md"
      onMouseOver={onMouseOver}
      onClick={onClick}
      cursor="pointer"
    >
      <Flex alignItems="center" gap={2} px={2}>
        <Box
          minH={10}
          minW={10}
          h={10}
          w={10}
          alignItems="center"
          justifyContent={'center'}
        >
          {isChecklist ? (
            <Icon
              w="10"
              h="10"
              as={FaClipboardList}
              color={selected ? 'brand.white' : 'brand.secondary'}
            />
          ) : (
            <Image
              src={getProductImage(result.productKind!)}
              alt={t('products:image_alt_product_icon')}
              w="full"
              h="full"
            />
          )}
        </Box>
        <VStack lineHeight={0.8} alignItems="start" overflowX={'hidden'} p={2}>
          <Text
            color={selected ? 'brand.white' : 'brand.secondary'}
            fontSize="xs"
            textTransform="capitalize"
          >
            {getDisplayType(result) ?? t('checklists:kind_checklist')}
          </Text>
          <Text
            w="full"
            color={selected ? 'brand.white' : 'brand.primary'}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            dangerouslySetInnerHTML={{
              __html: result.highlightedName ?? result.name,
            }}
          />
        </VStack>
      </Flex>
    </Box>
  );
};
