import { VStack, Heading, Text, HStack, Button } from '@chakra-ui/react';

type Props = {
  title: string;
  description: string;
  label?: string;
  handleOnClick?: () => void;
  children: React.ReactNode;
};

export function SettingsContent({
  title,
  description,
  label,
  handleOnClick,
  children,
}: Props) {
  return (
    <VStack alignItems="start" gap={5} h="full" w="full">
      <VStack w="full" h="full" alignItems="start" gap={5} lineHeight={1.2}>
        <HStack w="full" justifyContent="space-between">
          <VStack alignItems="flex-start">
            <Heading
              as="h2"
              fontSize={{ sm: 'lg', md: 'xl', lg: '2xl' }}
              fontWeight="medium"
            >
              {title}
            </Heading>
            <Text maxW="lg" fontSize={{ base: 'sm', md: 'md' }}>
              {description}
            </Text>
          </VStack>
          {label && <Button onClick={handleOnClick}>{label}</Button>}
        </HStack>
        <VStack
          h="full"
          w="full"
          alignItems="flex-start"
          overflow="auto"
          pr={5}
        >
          {children}
        </VStack>
      </VStack>
    </VStack>
  );
}
