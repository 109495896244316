import { Box, Flex } from '@chakra-ui/react';
import { t } from 'i18next';
import { useEffect } from 'react';
import { BsFillShieldLockFill } from 'react-icons/bs';
import { FaUserSecret, FaCreditCard } from 'react-icons/fa';
import { RiOrganizationChart } from 'react-icons/ri';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import {
  Layout,
  NavigationLink,
  AuthorizedUsersSettings,
  ImpersonatorSettings,
  SubscriptionSettings,
} from '../modules';
import { OrganizationUnitsSettings } from '../modules/settings/components/OrganizationUnitsSettings';

export function SettingsPage() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/settings')
      navigate('/settings/access-management');
  }, []);

  return (
    <Layout title={t('settings:page_title')}>
      <Flex
        p={{ base: 2, md: 4, lg: 8 }}
        w="full"
        h="full"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          w="full"
          h="full"
          flexDir={{ base: 'column', md: 'row' }}
          bgColor={'brand.white'}
          p={{ base: 4, lg: 12 }}
          pl={{ base: 4, lg: 6 }}
          borderRadius="md"
          border="1px solid #e5e5e5"
        >
          <Flex
            alignItems="start"
            flexDir={{ base: 'row', md: 'column' }}
            mr={{ base: 4, lg: 12 }}
            pr={{ base: 4, lg: 6 }}
            pb={{ base: 4, md: 0 }}
            gap={{ base: 2, md: 6 }}
            borderColor="brand.gray"
            borderRight={{ base: 'none', md: '1px solid #F1F1F1' }}
          >
            <NavigationLink
              name={t('settings:btn_access_management')}
              routeUrl="/settings/access-management"
              icon={BsFillShieldLockFill}
            />
            <NavigationLink
              name={t('settings:btn_organizational_units')}
              routeUrl="/settings/organizational-units"
              icon={RiOrganizationChart}
            />
            <NavigationLink
              name={t('settings:btn_impersonator')}
              routeUrl="/settings/impersonator"
              icon={FaUserSecret}
            />
            <NavigationLink
              name={t('settings:btn_subscription')}
              routeUrl="/settings/subscription"
              icon={FaCreditCard}
            />
          </Flex>
          <Box
            w="full"
            display={{ base: 'block', md: 'none' }}
            borderBottom={{ base: '1px solid #F1F1F1', md: 'none' }}
            mb={{ base: 4, md: 0 }}
          />
          <Routes>
            <Route
              path="/access-management"
              element={<AuthorizedUsersSettings />}
            />
            <Route
              path="/organizational-units"
              element={<OrganizationUnitsSettings />}
            />
            <Route path="/impersonator" element={<ImpersonatorSettings />} />
            <Route path="/subscription/*" element={<SubscriptionSettings />} />
          </Routes>
        </Flex>
      </Flex>
    </Layout>
  );
}
