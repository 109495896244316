import { VStack, Image, Text, Button } from '@chakra-ui/react';
import { useGoogleLogin } from '@react-oauth/google';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate, useSearchParams } from 'react-router-dom';

import { BackgroundContainer, notifyUser, useAuth } from '../modules';

export function LoginPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');

  const { isAuthenticated, isLoading, loginObject, login } = useAuth();

  const handleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (response) => {
      login(response.code);
    },
    onError: () => {
      notifyUser(t('login:toast_error_google_login'), true);
    },
  });

  if (isAuthenticated && !isLoading) {
    let destination = redirect || '/';

    const hasTenant = loginObject?.hasTenant;
    const hasActiveSubscription = loginObject?.hasActiveSubscription;
    const isAdmin = loginObject?.isAdmin;

    if (hasTenant && hasActiveSubscription) {
      // NOTE: The user can make use of Workspace Warden and should be redirected to the products page
      destination = redirect || '/';
    }

    if (!hasTenant && !isAdmin) {
      // NOTE: The user can not make use of Workspace Warden and should ask an admin to install Workspace Warden
      destination = '/ask-admin-installation';
    }

    if (!hasTenant && isAdmin) {
      // NOTE: The admin can not make use of Workspace Warden and should install it via the Marketplace
      destination = '/marketplace-installation';
    }

    if (hasTenant && !hasActiveSubscription && !isAdmin) {
      // NOTE: The user can not make use of Workspace Warden and should ask the admin to resubscribe
      destination = '/ask-admin-subscription';
    }

    if (hasTenant && !hasActiveSubscription && isAdmin) {
      // NOTE: The admin can not make use of Workspace Warden and should be redirected to the subscription page
      destination = '/settings/subscription';
    }

    return <Navigate replace to={destination} />;
  }

  return (
    <BackgroundContainer>
      <Helmet>
        <title>Workspace Warden - Login</title>
      </Helmet>
      <VStack
        px={12}
        py={8}
        maxW="600px"
        zIndex={1}
        gap={10}
        borderRadius="lg"
        alignItems="start"
      >
        <Image
          mx="auto"
          h="120px"
          src="/logo.svg"
          alt="Workspace Warden logo"
        />
        <VStack w="full" gap={3}>
          <Text textAlign="justify" lineHeight={1.8}>
            {t('login:description')}
          </Text>
        </VStack>
        <Button id="login-user" mx="auto" onClick={handleLogin}>
          {t('login:btn_google_login')}
        </Button>
      </VStack>
    </BackgroundContainer>
  );
}
