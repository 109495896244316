import {
  Button,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { SearchResultCard } from '../../../../search';
import { useSearchControllerSearchAll } from '@hooks';
import { GlobalSearchResultDto } from '@models';
import { useDebounce } from 'use-debounce';
import { getSearchResultKey } from '@utils';

type Props = {
  isOpen: boolean;
};

export const MobileSearch = ({ isOpen }: Props) => {
  /** How many results should be shown. Backend returns 20 */
  const MAX_RESULTS = 10;

  const { t } = useTranslation();
  const { isOpen: isSearchOpen, onOpen, onClose } = useDisclosure();
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 150);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [items, setItems] = useState<GlobalSearchResultDto[]>([]);

  const navigate = useNavigate();

  const isMac = navigator.userAgent.search('Mac') !== -1;

  const handleGlobalKeyDown = useCallback((event: KeyboardEvent) => {
    if (isMac) {
      if (event.metaKey && event.key === 'k') {
        onOpen();
      }
      return;
    }
    if (event.ctrlKey && event.key === 'i') onOpen();
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleGlobalKeyDown);
    return () => {
      document.removeEventListener('keydown', handleGlobalKeyDown);
    };
  }, [handleGlobalKeyDown]);

  const { data: results, isFetching } =
    useSearchControllerSearchAll(debouncedQuery);

  useEffect(() => {
    if (!isFetching) setItems(results?.data || []);
  }, [results, isFetching]);

  const onSearch = (query: string) => {
    setQuery(query);
    setSelectedIndex(0);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    let index;
    if (!results) return;
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        if (selectedIndex === 0) return;
        index = selectedIndex > 0 ? selectedIndex - 1 : 0;
        setSelectedIndex(index);
        break;
      case 'ArrowDown':
        index =
          selectedIndex < MAX_RESULTS - 1 ? selectedIndex + 1 : MAX_RESULTS - 1;
        setSelectedIndex(index);
        break;
      case 'Enter':
        handleItemSelection(results.data[selectedIndex]);
        break;
      default:
        break;
    }
  };

  const handleItemSelection = (result: GlobalSearchResultDto) => {
    if (!result) return;
    handleClose();

    if (result.type === 'CHECKLIST') {
      navigate(`/checklists/${result.id}`);
    } else {
      navigate(`/products/${result.productKind!.toLowerCase()}/${result.id}`);
    }
  };

  const handleClose = () => {
    onClose();
    setQuery('');
  };

  return (
    <>
      <Button
        w="full"
        variant="ghost"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        onClick={onOpen}
        color="brand.secondary"
        _hover={{ color: 'brand.primary' }}
        px={4}
        py={6}
        gap={6}
      >
        <Icon as={FaSearch} boxSize={4} />
        {isOpen && (
          <Text fontWeight="normal" fontSize="sm">
            {t('sidebar:btn_search')}
          </Text>
        )}
      </Button>

      <Modal isOpen={isSearchOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent onKeyDown={handleKeyDown}>
          <ModalBody p={5}>
            <HStack gap={2} bg="brand.gray" borderRadius="md" px={4}>
              <Icon as={FaSearch} fontSize="md" color="brand.secondary" />
              <Input
                value={query}
                onChange={(e) => onSearch(e.target.value)}
                placeholder={t('sidebar:placeholder_search')}
                border="none"
                _focus={{
                  boxShadow: 'none',
                }}
                style={{
                  marginLeft: 0,
                  paddingLeft: '4px',
                }}
                _hover={{
                  outline: 'none',
                }}
              />
            </HStack>
            <VStack
              alignItems="flex-start"
              mt={debouncedQuery && items.length ? 5 : 0}
            >
              {items.slice(0, MAX_RESULTS).map((result, index) => (
                <SearchResultCard
                  result={result}
                  selected={index === selectedIndex}
                  onMouseOver={() => setSelectedIndex(index)}
                  onClick={() => handleItemSelection(result)}
                  key={getSearchResultKey(result)}
                />
              ))}
            </VStack>
            {debouncedQuery && !isFetching && !items.length && (
              <Text p={5} pb={0} textAlign="center">
                {t('sidebar:placeholder_no_results')}
              </Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
