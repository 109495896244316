import { Box, VStack, HStack, Heading, Text } from '@chakra-ui/react';
import { GetBillingInfoDto } from '@models';
import { useTranslation } from 'react-i18next';

type Props = {
  billingInfo: GetBillingInfoDto;
};

export function SubscriptionUserDetails({ billingInfo }: Props) {
  const { t } = useTranslation();
  return (
    <VStack w="full" alignItems="flex-start" gap={8} mb={8}>
      <VStack w="full" alignItems="flex-start">
        <HStack w="full" gap={5}>
          <Heading as="h2" size="md" fontWeight="medium">
            {t('subscriptions:subheading_personal')}
          </Heading>
          <Box h="1px" w="full" bg="gray.300" mt={1}></Box>
        </HStack>
        <Text>
          {billingInfo.firstName} {billingInfo.lastName}
        </Text>
        <Text>{billingInfo.companyName}</Text>
        <Text>{billingInfo.address}</Text>
        <Text>{billingInfo.postcode}</Text>
        <Text>{billingInfo.city}</Text>
        <Text>{billingInfo.country}</Text>
      </VStack>
      <VStack w="full" alignItems="flex-start">
        <HStack w="full" gap={5}>
          <Heading as="h2" size="md" fontWeight="medium">
            {t('subscriptions:subheading_contact')}
          </Heading>
          <Box h="1px" w="full" bg="gray.300" mt={1}></Box>
        </HStack>
        <Text>{billingInfo.phone}</Text>
        <Text>{billingInfo.email}</Text>
      </VStack>
    </VStack>
  );
}
