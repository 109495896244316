import { UpdateMemberRoleDtoRole } from '@models';
import { useTranslation } from 'react-i18next';

type Props = {
  value: keyof typeof UpdateMemberRoleDtoRole;
};

export function PermissionOption({ value }: Props) {
  const { t } = useTranslation();
  return (
    <option value={UpdateMemberRoleDtoRole[value]}>
      {t(`products:permission_${value}`)}
    </option>
  );
}
