import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  onHandleRenewSubscription: (renew: boolean) => void;
  days: number;
};

export function CancelSubscriptionModal({
  isOpen,
  onClose,
  onHandleRenewSubscription,
  days,
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('subscriptions:modal_header_cancel_subscription')}
        </ModalHeader>
        <ModalBody>
          {t('subscriptions:modal_body_cancel_subscription', { days: days })}
        </ModalBody>

        <ModalFooter>
          <Button variant="text" mr={3} onClick={onClose}>
            {t('globals:btn_cancel')}
          </Button>
          <Button
            id="renew-subscription"
            onClick={() => {
              onHandleRenewSubscription(false);
              onClose();
            }}
          >
            {t('globals:btn_continue')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
