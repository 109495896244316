import { Box, VStack, Text } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChecklistSearchResultDto, ProductDetailsDto } from '@models';
import { useSearchControllerSearchChecklists } from '@hooks';
import { useDebounce } from 'use-debounce';

type Props = {
  handler: (checklistId: string) => void;
  product: ProductDetailsDto;
};

export function LinkChecklist({ handler, product }: Props) {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 250);
  const [checklist, setChecklist] = useState<ChecklistSearchResultDto | null>(
    null,
  );

  const {
    data: results,
    isLoading,
    isFetching,
  } = useSearchControllerSearchChecklists(
    debouncedQuery!,
    {},
    {
      query: {
        enabled: !!debouncedQuery,
      },
    },
  );

  const linkableChecklists: ChecklistSearchResultDto[] = useMemo(() => {
    const linkable: ChecklistSearchResultDto[] = [];
    if (!results) return linkable;
    for (const checklist of results.data) {
      if (product.checklists?.find((c) => c.id === checklist.id)) continue;
      linkable.push(checklist);
    }
    return linkable;
  }, [results, product]);

  return (
    <Box w="full" mt="auto">
      <Select
        size="sm"
        menuPlacement="top"
        menuPosition="fixed"
        placeholder={t('products:placeholder_search_checklists')}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        options={linkableChecklists}
        onChange={(item) => {
          if (!item) return;
          setQuery('');
          setChecklist(null);
          handler(item.id);
        }}
        formatOptionLabel={(data, meta) =>
          meta.context === 'menu' ? (
            <VStack gap={0} alignItems="flex-start" overflow="hidden">
              <Box textTransform="lowercase" fontSize="sm" color="gray.400">
                {t('checklists:kind_checklist')}
              </Box>
              <Box
                color="brand.primary"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                w="full"
                dangerouslySetInnerHTML={{
                  __html: data.highlightedName!,
                }}
              />
            </VStack>
          ) : (
            <Box overflow="hidden" w="full">
              <Text
                color="brand.primary"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                w="full"
              >
                {checklist
                  ? data?.name
                  : t('checklists:placeholder_search_products')}
              </Text>
            </Box>
          )
        }
        isLoading={isFetching || isLoading}
        inputValue={query}
        onInputChange={(value, action) => {
          if (
            action?.action !== 'input-blur' &&
            action?.action !== 'menu-close'
          ) {
            setQuery(value);
          }
        }}
        filterOption={null}
        backspaceRemovesValue={true}
        onBlur={(e) => {
          e.preventDefault();
        }}
        selectedOptionColor="transparent"
        chakraStyles={{
          menu: (base, { selectProps }) => ({
            ...base,
            display: selectProps.options.length < 1 ? 'none' : 'inline-flex',
            top: 'auto',
            bottom: '100%',
            marginBottom: '35px',
          }),
          control: (base) => ({
            ...base,
            bg: 'brand.gray',
            color: 'brand.primary',
            borderColor: 'brand.gray',
            borderRadius: 'lg',
            maxW: 'full',
            _hover: {
              cursor: 'text',
            },
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            bg: 'brand.gradient',
            color: 'brand.white',
          }),
          placeholder: (base) => ({
            ...base,
            color: 'brand.secondary',
          }),
          container: (provided) => ({
            ...provided,
          }),
        }}
      />
    </Box>
  );
}
