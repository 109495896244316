import { CloseIcon } from '@chakra-ui/icons';
import {
  AccordionButton,
  Accordion,
  AccordionItem,
  AccordionPanel,
  List,
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
  AvatarBadge,
  Spinner,
} from '@chakra-ui/react';
import { css } from '@emotion/react';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronDown } from 'react-icons/fi';

import {
  ProductChecklistDto,
  ProductDetailsDto,
  ProductDetailsDtoKind,
  ProductMemberDto,
  ProductMemberDtoRole,
  ProductMemberDtoType,
  RemoveMemberDto,
  UpdateMemberRoleDto,
  UpdateMemberRoleDtoRole,
} from '@models';
import { PermissionOption } from '../PermissionOption';
import { GroupMember } from '../GroupMember';
import {
  useProductControllerDeleteMember,
  useProductControllerUpdateMember,
} from '@hooks';
import { notifyUser } from 'src/modules/common';
import { hasWarning } from '../../utils';

type Props = {
  product: ProductDetailsDto;
  member: ProductMemberDto;
  refetch: () => void;
};

export function ProductMemberListItem({ product, member, refetch }: Props) {
  const [displayIcon, setDisplayIcon] = useState('none');
  const { isOpen, onOpen: onOpenConfirmationModal, onClose } = useDisclosure();
  const [changingMember, setChangingMember] = useState<string | null>(null);
  const [role, setRole] = useState<ProductMemberDtoRole>(member.role);

  const { t } = useTranslation();

  const { mutate: removeMember } = useProductControllerDeleteMember({
    mutation: {
      onSuccess: () => {
        notifyUser(t('products:toast_success_delete_member'), false);
      },
      onError: () => {
        notifyUser(t('products:toast_error_delete_member'), true);
        onClose();
      },
      onSettled: () => {
        refetch();
      },
    },
  });

  function handleDeleteMember(member: RemoveMemberDto) {
    removeMember({
      id: product.id,
      kind: product.kind,
      data: member,
    });
  }

  const { mutate: updateMember, isPending: isChangingPermission } =
    useProductControllerUpdateMember({
      mutation: {
        onSuccess: () => {
          notifyUser(t('products:toast_success_update_member'), false);
        },
        onError: () => {
          notifyUser(t('products:toast_error_update_member'), true);
        },
        onSettled: () => {
          refetch();
        },
      },
    });

  function handleUpdateMemberRole(member: UpdateMemberRoleDto) {
    updateMember({ id: product.id, kind: product.kind, data: member });
  }

  function memberIsPartOfChecklist(
    productMember: ProductMemberDto,
    groupMember: string,
  ) {
    return (
      productMember.members?.some((member) => member === groupMember) ||
      productMember.members?.map((a) => a).indexOf(productMember.email) > -1
    );
  }

  const hasDeviationWarning = hasWarning(member, product.deviations);

  const isGroup = member.members?.length > 0;

  return (
    <>
      <Accordion key={member.email} allowToggle>
        <AccordionItem border="none" outline="none" mx={1}>
          <HStack
            as={AccordionButton}
            px={2}
            py={3}
            justifyContent="space-between"
            borderRadius="md"
            _focusVisible={{
              boxShadow: isGroup && 'none',
            }}
            _hover={{
              bg: 'transparent',
              cursor: isGroup ? 'pointer' : 'default',
            }}
            css={css`
              cursor: default;

              &:hover .delete-icon {
                display: flex;
              }
            `}
          >
            <HStack w="full" justifyContent="space-between">
              <Flex w="full" flexDirection="row" alignItems="center">
                <Avatar
                  name={member.name || member.email}
                  src={member.photoLink || ''}
                  bg="brand.gray"
                  color="brand.primary"
                  w={{ base: 12, lg: 14 }}
                  h={{ base: 12, lg: 14 }}
                  mr={{ base: 4, lg: 4 }}
                >
                  {!isGroup && (
                    <Box
                      position="absolute"
                      justifyContent="center"
                      alignItems="center"
                      onClick={() => {
                        onOpenConfirmationModal();
                        setDisplayIcon('flex');
                      }}
                      _hover={{ cursor: 'pointer' }}
                      className="delete-icon"
                      display={displayIcon}
                      borderRadius="full"
                      bg="brand.gradient"
                      opacity={0.9}
                      w="full"
                      h="full"
                    >
                      <CloseIcon
                        mt="auto"
                        position="absolute"
                        color="brand.gray"
                        boxSize="1rem"
                      />
                    </Box>
                  )}
                  {isGroup && (
                    <AvatarBadge
                      display="flex"
                      bg="brand.secondary"
                      borderColor="brand.white"
                      boxSize="1.3rem"
                      justifyContent="center"
                      alignItems="center"
                      borderWidth="0.15em"
                    >
                      <Text
                        w="full"
                        color="brand.white"
                        textAlign="center"
                        fontSize="xs"
                      >
                        {member.members.length}
                      </Text>
                    </AvatarBadge>
                  )}
                  {!isGroup && hasDeviationWarning && (
                    <Tooltip
                      label={t('products:tooltip_deviation')}
                      placement="top"
                    >
                      <AvatarBadge
                        display="flex"
                        bg="brand.secondary"
                        borderColor="brand.white"
                        boxSize="1.3rem"
                        justifyContent="center"
                        alignItems="center"
                        borderWidth="0.15em"
                        p={2}
                      >
                        <Text w="5" h="5" color="brand.white" fontSize="sm">
                          !
                        </Text>
                      </AvatarBadge>
                    </Tooltip>
                  )}
                </Avatar>
                <VStack
                  lineHeight="1"
                  alignItems="start"
                  justifyContent="start"
                >
                  {member.name && (
                    <Text fontSize="sm" color="brand.priamry" textAlign="start">
                      {member.name}
                    </Text>
                  )}

                  <Text
                    color="brand.secondary"
                    fontSize="sm"
                    pt="0"
                    textAlign="start"
                  >
                    {member.email}
                  </Text>
                </VStack>
              </Flex>

              {product.kind !== ProductDetailsDtoKind.MAILBOX && (
                <Select
                  w="fit-content"
                  variant="solid"
                  bg="transparent"
                  textAlign="right"
                  icon={
                    isChangingPermission && changingMember === member.email ? (
                      <Flex ml={4} justify="center" alignItems="center">
                        <Spinner size="sm" />
                      </Flex>
                    ) : (
                      <Icon as={FiChevronDown} ml={2} fontSize="xs" />
                    )
                  }
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  onChange={(event) => {
                    event.stopPropagation();
                    const val = event.target.value as UpdateMemberRoleDtoRole;
                    if (!val) return;
                    setChangingMember(member.email);
                    setRole(val);
                    handleUpdateMemberRole({
                      permissionId: member.permissionId,
                      role: val,
                    });
                  }}
                  borderRadius="md"
                  size="sm"
                  isDisabled={
                    isChangingPermission && changingMember === member.email
                  }
                  value={role}
                  _hover={{
                    cursor: 'pointer',
                  }}
                >
                  {product.kind === ProductDetailsDtoKind.DRIVE && (
                    <>
                      <PermissionOption
                        value={UpdateMemberRoleDtoRole.reader}
                      />
                      <PermissionOption
                        value={UpdateMemberRoleDtoRole.commenter}
                      />
                      <PermissionOption
                        value={UpdateMemberRoleDtoRole.writer}
                      />
                      <PermissionOption
                        value={UpdateMemberRoleDtoRole.fileOrganizer}
                      />
                      <PermissionOption
                        value={UpdateMemberRoleDtoRole.organizer}
                      />
                    </>
                  )}

                  {product.kind === ProductDetailsDtoKind.CALENDAR && (
                    <>
                      <PermissionOption
                        value={UpdateMemberRoleDtoRole.freeBusyReader}
                      />
                      <PermissionOption
                        value={UpdateMemberRoleDtoRole.reader}
                      />
                      <PermissionOption
                        value={UpdateMemberRoleDtoRole.writer}
                      />
                      <PermissionOption value={UpdateMemberRoleDtoRole.owner} />
                    </>
                  )}
                </Select>
              )}
            </HStack>
          </HStack>
          {isGroup && (
            <AccordionPanel pb={4}>
              <List spacing={2}>
                {member.members.map((m) => (
                  <GroupMember
                    key={m}
                    email={m}
                    isPartOfChecklist={memberIsPartOfChecklist(member, m)}
                  />
                ))}
              </List>
            </AccordionPanel>
          )}
        </AccordionItem>
      </Accordion>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('products:modal_header_remove_member')}</ModalHeader>
          <ModalBody>
            <Text>
              {t('products:modal_body_remove_member', {
                name: member.name,
              })}
            </Text>
          </ModalBody>
          <ModalFooter display="flex" gap={5}>
            <Button
              variant="text"
              onClick={() => {
                onClose();
                setDisplayIcon('none');
              }}
            >
              {t('globals:btn_cancel')}
            </Button>
            <Button
              id="delete-member"
              variant="solid"
              onClick={() => handleDeleteMember(member)}
            >
              {t('globals:btn_continue')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
