import { TabPanels } from '@chakra-ui/react';

type Props = {
  children: React.ReactNode;
};

export function CustomTabPanels({ children }: Props) {
  return (
    <TabPanels h={{ base: 'calc(100% - 100px)', lg: 'full' }} overflow="hidden">
      {children}
    </TabPanels>
  );
}
