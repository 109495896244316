import {
  Box,
  Button,
  Flex,
  FormControl,
  Icon,
  IconButton,
  Input,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { FaClipboardList } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import {
  useChecklistControllerRemove,
  useChecklistControllerRename,
} from '@hooks';
import { notifyUser } from '../../../common';
import { ChecklistDto } from '@models';

type ChecklistsListItemProps = {
  checklist: ChecklistDto;
  selected: boolean;
  onSelect: (checklist: ChecklistDto) => void;
  refetchChecklists: () => void;
};

export function ChecklistsListItem({
  checklist,
  selected,
  onSelect,
  refetchChecklists,
}: ChecklistsListItemProps) {
  const { t } = useTranslation();
  const [checklistName, setChecklistName] = useState(checklist.name);
  const {
    onOpen: onOpenDeleteChecklist,
    onClose: onCloseDeleteChecklist,
    isOpen: deleteChecklistIsOpen,
  } = useDisclosure();

  const {
    onOpen: onOpenEditChecklist,
    onClose: onCloseEditChecklist,
    isOpen: editChecklistIsOpen,
  } = useDisclosure();

  const { mutate: renameChecklist } = useChecklistControllerRename({
    mutation: {
      onError() {
        notifyUser(t('checklists:toast_error_edit_checklist'), true);
      },
      onSuccess() {
        notifyUser(t('checklists:toast_success_edit_checklist'), false);
        refetchChecklists();
      },
    },
  });

  const { mutate: removeChecklist } = useChecklistControllerRemove({
    mutation: {
      onError() {
        notifyUser(t('checklists:toast_error_delete_checklist'), true);
      },
      onSuccess() {
        notifyUser(
          t('checklists:toast_success_delete_checklist', {
            name: checklist.name,
          }),
          false,
        );
        refetchChecklists();
      },
    },
  });

  const navigate = useNavigate();

  function onRemoveChecklist() {
    removeChecklist({ id: checklist.id });
    navigate('/checklists', {
      state: {},
    });
  }

  const scroll = useCallback((element: HTMLLIElement) => {
    if (selected && element !== null) {
      element.scrollIntoView();
    }
  }, []);

  return (
    <ListItem
      borderRadius="md"
      flex={1}
      fontSize="sm"
      bg={selected ? 'brand.gradient' : 'brand.white'}
      onClick={() => onSelect(checklist)}
      tabIndex={1}
      ref={scroll}
      _hover={{
        cursor: 'pointer',
      }}
    >
      <Flex justifyContent="space-between" alignItems="center" gap={4} p={4}>
        <Box
          boxSize={{ base: 8, lg: 10 }}
          color={selected ? 'brand.white' : 'brand.secondary'}
        >
          <Icon w="full" h="full" as={FaClipboardList} />
        </Box>
        <Flex
          direction="column"
          flex={1}
          lineHeight={{ base: 1.3, lg: 1.4 }}
          overflow="hidden"
          w="0"
        >
          <Text
            color={selected ? 'brand.white' : 'brand.secondary'}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            textTransform="capitalize"
            fontSize="xs"
          >
            {t('checklists:kind_checklist')}
          </Text>
          <Text
            pr={4}
            color={selected ? 'brand.white' : 'brand.primary'}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {checklist.name}
          </Text>
        </Flex>
        {!checklist?.isReadOnly && (
          <Flex gap={6} color={selected ? 'brand.white' : 'brand.secondary'}>
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<Icon as={BiDotsVerticalRounded} fontSize="2xl" />}
                bg="transparent"
                color={selected ? 'brand.white' : 'brand.secondary'}
                _hover={{ bg: 'transparent' }}
                _active={{ bg: 'transparent' }}
              >
                {t('checklists:menu_btn_actions')}
              </MenuButton>
              <MenuList color="brand.primary">
                <MenuItem
                  _hover={{ bg: 'brand.gray' }}
                  _active={{ bg: 'brand.gray' }}
                  _focus={{ bg: 'brand.gray' }}
                  onClick={onOpenEditChecklist}
                >
                  {t('checklists:menu_item_edit')}
                </MenuItem>
                <MenuItem
                  _hover={{ bg: 'brand.gray' }}
                  _active={{ bg: 'brand.gray' }}
                  _focus={{ bg: 'brand.gray' }}
                  onClick={onOpenDeleteChecklist}
                >
                  {t('checklists:menu_item_delete')}
                </MenuItem>
              </MenuList>
            </Menu>

            <Modal
              onClose={onCloseDeleteChecklist}
              isOpen={deleteChecklistIsOpen}
              isCentered
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>
                  {t('checklists:modal_header_delete_checklist')}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {t('checklists:modal_body_delete_checklist', {
                    name: checklist.name,
                  })}
                </ModalBody>
                <ModalFooter>
                  <Button variant="text" onClick={onCloseDeleteChecklist}>
                    {t('globals:btn_cancel')}
                  </Button>
                  <Button id="remove-checklist" onClick={onRemoveChecklist}>
                    {t('globals:btn_continue')}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <Modal
              onClose={onCloseEditChecklist}
              isOpen={editChecklistIsOpen}
              isCentered
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>
                  {t('checklists:modal_header_edit_checklist')}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <Input
                      type="text"
                      bg="brand.gray"
                      borderColor="brand.gray"
                      placeholder={checklistName}
                      value={checklistName}
                      onChange={(e) => setChecklistName(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          renameChecklist({
                            id: checklist.id,
                            data: {
                              name: checklistName.trim(),
                            },
                          });
                          onCloseEditChecklist();
                        }
                      }}
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button variant="text" onClick={onCloseEditChecklist}>
                    {t('globals:btn_cancel')}
                  </Button>
                  <Button
                    id="rename-checklist"
                    onClick={() => {
                      renameChecklist({
                        id: checklist.id,
                        data: {
                          name: checklistName.trim(),
                        },
                      });
                      onCloseEditChecklist();
                    }}
                  >
                    {t('globals:btn_save')}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
        )}
      </Flex>
    </ListItem>
  );
}
