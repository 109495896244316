import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaClipboardList } from 'react-icons/fa';
import { Link as ReactLink } from 'react-router-dom';

import { ProductChecklistDto } from '@models';

type Props = {
  checklist: ProductChecklistDto;
  unlinkHandler(checklistId: string): void;
};

export function ProductChecklistsListItem({ checklist, unlinkHandler }: Props) {
  const { t } = useTranslation();
  const [displayIcon, setDisplayIcon] = useState('none');
  const { isOpen, onOpen: onOpenConfirmationModal, onClose } = useDisclosure();

  function handleUnlinkChecklist() {
    unlinkHandler(checklist.id);
    onClose();
  }

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        px={3}
        css={css`
          cursor: default;
          &:hover .delete-icon {
            display: flex;
          }
        `}
      >
        <Flex justifyContent="start" alignItems="center" w="full" gap={3}>
          <Flex
            w={{ base: 12, lg: 14 }}
            h={{ base: 12, lg: 14 }}
            borderRadius="full"
            bg="brand.secondary"
            justifyContent="center"
            alignItems="center"
            color="brand.white"
          >
            <Icon boxSize={{ base: 5, lg: 6 }} as={FaClipboardList} />
            <Box
              position="absolute"
              justifyContent="center"
              alignItems="center"
              onClick={() => {
                onOpenConfirmationModal();
                setDisplayIcon('flex');
              }}
              _hover={{ cursor: 'pointer' }}
              className="delete-icon"
              display={displayIcon}
              borderRadius="full"
              bg="brand.gradient"
              opacity={0.9}
              w={{ base: 12, lg: 14 }}
              h={{ base: 12, lg: 14 }}
            >
              <CloseIcon
                mt="auto"
                position="absolute"
                color="brand.gray"
                boxSize="1rem"
              />
            </Box>
          </Flex>
          <Link
            mx={1}
            flex={1}
            borderRadius="md"
            as={ReactLink}
            to={`/checklists/${checklist.id}`}
            _focusVisible={{
              boxShadow: 'none',
            }}
            _hover={{ textDecor: 'none' }}
          >
            <Flex direction="column" flexGrow={2}>
              <Text fontSize="sm" color="brand.secondary">
                {t(`products:kind_checklist`)}
              </Text>
              <Text
                fontSize="sm"
                wordBreak="break-word"
                pr={6}
                color="brand.primary"
              >
                {checklist.name}
              </Text>
            </Flex>
          </Link>
        </Flex>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t('checklists:modal_header_unlink_checklist')}
          </ModalHeader>
          <ModalBody>
            <Text>
              {t('checklists:modal_body_unlink_checklist', {
                name: checklist.name,
              })}
            </Text>
          </ModalBody>
          <ModalFooter display="flex" gap={5}>
            <Button
              variant="text"
              onClick={() => {
                setDisplayIcon('none');
                onClose();
              }}
            >
              {t('globals:btn_cancel')}
            </Button>
            <Button
              id="unlink-checklist"
              variant="solid"
              onClick={handleUnlinkChecklist}
            >
              {t('globals:btn_continue')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
