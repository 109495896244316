/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Workspace Warden
 * OpenAPI spec version: 0.0.1
 */

export type ProductSearchResultDtoKind =
  (typeof ProductSearchResultDtoKind)[keyof typeof ProductSearchResultDtoKind];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductSearchResultDtoKind = {
  DRIVE: 'DRIVE',
  CALENDAR: 'CALENDAR',
  MAILBOX: 'MAILBOX',
} as const;
