import { Box, ListItem, Flex, Image, Text, Icon } from '@chakra-ui/react';
import { t } from 'i18next';
import { FaClipboardList } from 'react-icons/fa';

import { GlobalSearchResultDto, ProductDetailsDtoKind } from '@models';
import { getProductImage } from '@utils';

type Props = {
  result: GlobalSearchResultDto;
  selectHandler: (result: GlobalSearchResultDto) => void;
  selectedListItem: GlobalSearchResultDto | null;
};

export function SearchDetails({
  result,
  selectHandler,
  selectedListItem,
}: Props) {
  const isSelected = (): boolean => {
    if (!selectedListItem) return false;
    if (selectedListItem.type === 'PRODUCT') {
      return (
        selectedListItem.id === result.id &&
        selectedListItem.productKind === result.productKind
      );
    } else {
      return selectedListItem.id === result.id;
    }
  };

  const isChecklist = result.type === 'CHECKLIST';

  return (
    <ListItem
      borderRadius="md"
      flex={1}
      fontSize="sm"
      bg={isSelected() ? 'brand.gradient' : 'brand.white'}
      onClick={() => selectHandler(result)}
      tabIndex={1}
      _hover={{
        cursor: 'pointer',
      }}
    >
      <Flex justifyContent="space-between" alignItems="center" gap={4} p={4}>
        <Box boxSize={{ base: 8, md: 10 }}>
          {isChecklist ? (
            <Icon
              w="full"
              h="full"
              as={FaClipboardList}
              color={isSelected() ? 'brand.white' : 'brand.secondary'}
            />
          ) : (
            <Image
              src={getProductImage(result.productKind as ProductDetailsDtoKind)}
              alt={t('products:image_alt_product_icon')}
            />
          )}
        </Box>
        <Flex
          direction="column"
          flex={1}
          lineHeight={{ base: 1.3, md: 1.4 }}
          overflow="hidden"
          w="0"
        >
          <Text
            color={isSelected() ? 'brand.white' : 'brand.secondary'}
            textTransform="capitalize"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            fontSize="xs"
          >
            {isChecklist ? 'Checklist' : result.productKind?.toLowerCase()}
          </Text>
          <Text
            pr={4}
            color={isSelected() ? 'brand.white' : 'brand.primary'}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            dangerouslySetInnerHTML={{
              __html: result.highlightedName || result.name,
            }}
          ></Text>
        </Flex>
      </Flex>
    </ListItem>
  );
}
