import { Flex, useMediaQuery, VStack } from '@chakra-ui/react';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Header, Sidebar } from '../navigation';
import { SubscriptionBanner } from '../SubscriptionBanner';
import { useSubscriptionControllerGetMyCustomer } from '@hooks';
import { TenantSubscriptionStatusDtoSubscriptionType } from '@models';

interface ILayoutProps {
  title: string;
  children: React.ReactNode;
}

export const Layout = ({ title, children }: ILayoutProps) => {
  const [isMobile] = useMediaQuery('(max-width: 1024px)');

  const { data: customer } = useSubscriptionControllerGetMyCustomer();
  const hasTrial =
    customer?.data.subscriptionType ===
    TenantSubscriptionStatusDtoSubscriptionType.TRIAL;

  return (
    <>
      <Helmet>
        <title>Workspace Warden - {title}</title>
      </Helmet>
      <Flex
        w="full"
        h="full"
        zIndex={0}
        bgImage="url(/background.png)"
        bgSize="cover"
        bgPosition="center"
        overflow="hidden"
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <VStack h="full" w="full">
          {hasTrial && <SubscriptionBanner />}
          <Flex
            flex={1}
            w="full"
            mt={
              customer?.data.subscriptionType ===
              TenantSubscriptionStatusDtoSubscriptionType.TRIAL
                ? 14
                : 0
            }
            h={hasTrial ? 'calc(100% - 56px)' : 'full'}
            zIndex={1}
            justifyContent="space-between"
            gap={0}
            bgImage="url(/background.png)"
            bgSize="cover"
            bgPosition="center"
            flexDir={{ base: 'column', lg: 'row' }}
          >
            {isMobile ? <Header /> : <Sidebar />}
            <Flex
              w="full"
              h="full"
              zIndex={1}
              justifyContent="space-between"
              gap={0}
            >
              {children}
            </Flex>
          </Flex>
        </VStack>
      </Flex>
    </>
  );
};
