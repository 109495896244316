import { Flex, Heading, Button, Text, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Subscription } from './SubscriptionSettings';
import { TenantSubscriptionStatusDtoSubscriptionType } from '@models';

type Props = {
  subscription: Subscription;
  handleSelectSubscription: (subscription: Subscription) => void;
};

export function SubscriptionPricing({
  subscription,
  handleSelectSubscription,
}: Props) {
  const { t } = useTranslation();
  const isYearlySubscription =
    subscription.type === TenantSubscriptionStatusDtoSubscriptionType.YEARLY;

  return (
    <Flex
      flexDir="column"
      bg={isYearlySubscription ? 'url(/background.png)' : 'brand.gray'}
      bgPos="center"
      color="brand.primary"
      borderRadius="md"
      _hover={{ cursor: 'pointer' }}
      w={{ base: 'full', lg: 'fit-content' }}
    >
      {isYearlySubscription ? (
        <Box h={9} w="full" bg="brand.gradient" borderTopRadius="md">
          <Text
            color="brand.white"
            textTransform="uppercase"
            textAlign="center"
            p={2}
          >
            {t('subscriptions:label_discount')}
          </Text>
        </Box>
      ) : (
        <Box h={9}></Box>
      )}
      <Box w="full" px={10} py={8}>
        <Heading
          as="h3"
          size="sm"
          textTransform="uppercase"
          fontWeight="normal"
          textAlign="center"
          mb={2}
        >
          {subscription.type}
        </Heading>
        <Text textAlign="center" fontWeight="bold" fontSize="4xl">
          {subscription.price}
        </Text>
        <Text textAlign="center" fontSize="xs" textTransform="uppercase" mt={2}>
          {t('subscriptions:label_annual', { annual: subscription.annual })}
        </Text>
        <Button
          id="select-subsription"
          w="full"
          mt={10}
          px={8}
          fontSize="sm"
          fontWeight="normal"
          color="brand.white"
          letterSpacing={1.5}
          textTransform="uppercase"
          bg={isYearlySubscription ? 'brand.gradient' : 'brand.primary'}
          variant={isYearlySubscription ? 'solid' : 'text'}
          onClick={() => handleSelectSubscription(subscription)}
        >
          {t('subscriptions:btn_subscribe')}
        </Button>
      </Box>
    </Flex>
  );
}
