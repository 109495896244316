import { List } from '@chakra-ui/react';

import { OrganizationalUnitsListItem } from './OrganizationalUnitsListItem';

type AuthorizedUsersListProps = {
  units: string[];
  deleteHandler: (unit: string) => void;
};

export function OrganizationalUnitsList({
  units,
  deleteHandler,
}: AuthorizedUsersListProps) {
  return (
    <List>
      {units.map((unit, i) => (
        <OrganizationalUnitsListItem
          key={unit[i]}
          unit={unit}
          deleteHandler={deleteHandler}
        />
      ))}
    </List>
  );
}
