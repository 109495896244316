import { Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingPlaceholder } from '../../common';

import { SettingsContent } from './SettingsContent';
import { SubscriptionDetails } from './SubscriptionDetails';
import { SubscriptionForm } from './SubscriptionForm';

import { SubscriptionPricing } from '.';
import {
  useSubscriptionControllerGetMyCustomer,
  useTenantControllerGetBillingInfo,
} from '@hooks';
import {
  SubscriptionInfoDtoType,
  TenantSubscriptionStatusDtoSubscriptionType,
} from '@models';

export type Subscription = {
  id: number;
  type: SubscriptionInfoDtoType;
  price: string;
  annual: string;
};

const subscriptionTypes: Subscription[] = [
  {
    id: 0,
    type: SubscriptionInfoDtoType.MONTHLY,
    price: '€ 2',
    annual: 'month',
  },
  {
    id: 1,
    type: SubscriptionInfoDtoType.YEARLY,
    price: '€ 10',
    annual: 'year',
  },
];

export function SubscriptionSettings() {
  const { t } = useTranslation();
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [selectedSubscription, setSelectedSubscription] =
    useState<Subscription | null>(null);

  const { data: tenant, isLoading: isLoadingTenant } =
    useSubscriptionControllerGetMyCustomer();

  const { data: billingInfo, isLoading: isLoadingBillingInfo } =
    useTenantControllerGetBillingInfo();

  const onHandleSelectSubscription = (subscription: Subscription) => {
    setSelectedSubscription(subscription);
    setIsSubscribing(true);
  };

  const onHandleCancelBillingInformation = (
    isLoadingTransactionUrl: boolean,
  ) => {
    setSelectedSubscription(null);
    setIsSubscribing(isLoadingTransactionUrl);
  };

  if (isLoadingTenant || isLoadingBillingInfo)
    return <LoadingPlaceholder text={t('subscriptions:loading_customer')} />;

  if (
    !isSubscribing &&
    (tenant?.data.subscriptionType ===
      TenantSubscriptionStatusDtoSubscriptionType.TRIAL ||
      !tenant?.data.hasActiveSubscription) &&
    !tenant?.data.renewEnabled
  )
    return (
      <SettingsContent
        title={t('subscriptions:title_pricing')}
        description={t('subscriptions:description_pricing')}
      >
        <Flex
          flexDir={{ base: 'column', sm: 'row', lg: 'row' }}
          w="full"
          gap={5}
        >
          {subscriptionTypes.map((subscription) => {
            return (
              <SubscriptionPricing
                key={subscription.id}
                subscription={subscription}
                handleSelectSubscription={onHandleSelectSubscription}
              />
            );
          })}
        </Flex>
      </SettingsContent>
    );

  if (
    isSubscribing &&
    selectedSubscription &&
    (tenant?.data.subscriptionType ===
      TenantSubscriptionStatusDtoSubscriptionType.TRIAL ||
      !tenant?.data.hasActiveSubscription)
  )
    return (
      <SettingsContent
        title={t('subscriptions:title_billing_form')}
        description={t('subscriptions:description_billing_form')}
      >
        <SubscriptionForm
          billingInfo={billingInfo ? billingInfo.data : null}
          selectedSubscription={selectedSubscription}
          handleCancelBillingInformation={() =>
            onHandleCancelBillingInformation(true)
          }
        />
      </SettingsContent>
    );

  if (isSubscribing && tenant && billingInfo)
    return (
      <SettingsContent
        title={t('subscriptions:title_billing_form')}
        description={t('subscriptions:description_update_billing_form')}
      >
        <SubscriptionForm
          billingInfo={billingInfo.data}
          handleCancelBillingInformation={() =>
            onHandleCancelBillingInformation(false)
          }
        />
      </SettingsContent>
    );

  return (
    <SettingsContent
      title={t('subscriptions:title_billing_details')}
      description={t('subscriptions:description_billing_details')}
      label={t('subscriptions:label_manage_billing_info')}
      handleOnClick={() => {
        setIsSubscribing(true);
      }}
    >
      {billingInfo && tenant && (
        <SubscriptionDetails
          tenant={tenant.data}
          billingInfo={billingInfo.data}
        />
      )}
    </SettingsContent>
  );
}
