import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {
  enChecklists,
  enDeviations,
  enFallback,
  enGlobals,
  enLogin,
  enOnboarding,
  enProducts,
  enSearch,
  enSettings,
  enSidebar,
  enSubscriptions,
  enTabs,
} from './locales/en';
import {
  nlChecklists,
  nlDeviations,
  nlFallback,
  nlGlobals,
  nlLogin,
  nlOnboarding,
  nlProducts,
  nlSearch,
  nlSettings,
  nlSidebar,
  nlSubscriptions,
  nlTabs,
} from './locales/nl';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      login: enLogin,
      tabs: enTabs,
      sidebar: enSidebar,
      checklists: enChecklists,
      products: enProducts,
      deviations: enDeviations,
      fallback: enFallback,
      globals: enGlobals,
      settings: enSettings,
      subscriptions: enSubscriptions,
      search: enSearch,
      onboarding: enOnboarding,
    },
    nl: {
      login: nlLogin,
      tabs: nlTabs,
      sidebar: nlSidebar,
      checklists: nlChecklists,
      products: nlProducts,
      deviations: nlDeviations,
      fallback: nlFallback,
      globals: nlGlobals,
      settings: nlSettings,
      subscriptions: nlSubscriptions,
      search: nlSearch,
      onboarding: nlOnboarding,
    },
  },
  supportedLngs: ['en', 'nl'],
  lng: window.navigator.language,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
