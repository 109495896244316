import { Box, HStack, Icon, Link } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { Link as ReactLink } from 'react-router-dom';

type Props = {
  name: string;
  routeUrl: string;
  onClose: () => void;
  icon: IconType;
};

export function HeaderLink({ name, routeUrl, onClose, icon }: Props) {
  return (
    <Link
      as={ReactLink}
      to={routeUrl}
      py={4}
      px={6}
      _hover={{ textDecor: 'none' }}
      onClick={onClose}
    >
      <HStack alignItems="center" justifyContent="flex-start" gap={6}>
        <Icon as={icon} boxSize={4} />
        <Box as="span" fontSize="sm" w="full">
          {name}
        </Box>
      </HStack>
    </Link>
  );
}
