import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading, HStack, Icon, Text } from '@chakra-ui/react';

type Props = {
  label?: string;
  description?: string;
  children: React.ReactNode;
  handleIsDetailsClosed?: () => void;
};

export function DetailViewContainer({
  label,
  description,
  children,
  handleIsDetailsClosed,
}: Props) {
  return (
    <Box h="full" w="full" overflow="hidden" bgColor="brand.white">
      <Flex direction="column" h="full">
        <HStack
          w="full"
          p={{ base: 4, md: 6, lg: 8 }}
          pb={{ base: 0, md: 2, lg: 0 }}
        >
          <Icon
            onClick={handleIsDetailsClosed}
            as={ChevronLeftIcon}
            display={{ base: 'block', md: 'none' }}
            w={8}
            h={8}
            _hover={{
              cursor: 'pointer',
            }}
          />
          <Box lineHeight={1.8} alignItems="start" w="full">
            <Text
              fontSize="sm"
              textTransform="capitalize"
              color="brand.secondary"
            >
              {label?.toLowerCase()}
            </Text>
            <Heading
              as="h2"
              fontSize={{ sm: 'lg', md: 'xl', lg: '2xl' }}
              fontWeight="medium"
              wordBreak="break-word"
            >
              {description}
            </Heading>
          </Box>
        </HStack>
        {children}
      </Flex>
    </Box>
  );
}
