import { Flex } from '@chakra-ui/react';

type Props = {
  children: JSX.Element[];
};

export function SidebarContainer({ children }: Props) {
  return (
    <Flex
      p={4}
      px={2}
      h="full"
      w={{ base: '65px', xl: '200px' }}
      position="sticky"
      flexDir="column"
      justifyContent="space-between"
      bgColor="brand.white"
      borderRightRadius="xl"
      lineHeight={0}
      zIndex={2}
    >
      <Flex
        as="nav"
        flexDir="column"
        h="full"
        w="100%"
        alignItems="space-between"
        justifyContent="space-between"
      >
        {children}
      </Flex>
    </Flex>
  );
}
