import { CloseIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type ChecklistMemberListItemProps = {
  member: string;
  deleteHandler(member: string): void;
  isReadonly: boolean;
};

export function ChecklistMembersListItem({
  member,
  deleteHandler,
  isReadonly,
}: ChecklistMemberListItemProps) {
  const { t } = useTranslation();
  const [displayIcon, setDisplayIcon] = useState('none');
  const { isOpen, onOpen: onOpenConfirmationModal, onClose } = useDisclosure();

  function handleDeleteMember() {
    if (isReadonly) {
      return;
    }
    deleteHandler(member);
  }

  return (
    <>
      <HStack
        px={3}
        py={3}
        justifyContent="space-between"
        css={css`
          cursor: default;

          &:hover .delete-icon {
            display: flex;
          }
        `}
      >
        <HStack flex={2} gap={3}>
          <Avatar
            name={member}
            bg="brand.secondary"
            color="brand.white"
            w={{ base: 12, lg: 14 }}
            h={{ base: 12, lg: 14 }}
          >
            <Box
              position="absolute"
              justifyContent="center"
              alignItems="center"
              onClick={() => {
                onOpenConfirmationModal();
                setDisplayIcon('flex');
              }}
              _hover={{ cursor: 'pointer' }}
              className="delete-icon"
              display={displayIcon}
              borderRadius="full"
              bg="brand.gradient"
              opacity={0.9}
              w="full"
              h="full"
            >
              <CloseIcon
                mt="auto"
                position="absolute"
                color="brand.gray"
                boxSize="1rem"
              />
            </Box>
          </Avatar>
          <VStack lineHeight="1" alignItems="start">
            <Text fontSize="sm" color="brand.priamry">
              {member.split('@')[0]}
            </Text>
            <Text color="brand.secondary" fontSize="sm" pt="0">
              {member}
            </Text>
          </VStack>
        </HStack>
      </HStack>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t('checklists:modal_header_unlink_member')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              {t('checklists:modal_body_unlink_member', { email: member })}
            </Text>
          </ModalBody>
          <ModalFooter display="flex" gap={5}>
            <Button
              variant="text"
              onClick={() => {
                setDisplayIcon('none');
                onClose();
              }}
            >
              {t('globals:btn_cancel')}
            </Button>
            <Button
              id="delete-member"
              variant="solid"
              onClick={handleDeleteMember}
            >
              {t('globals:btn_continue')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
