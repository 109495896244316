import { TabPanel } from '@chakra-ui/react';

type Props = {
  children: React.ReactNode;
};

export function CustomTabPanel({ children, ...props }: Props) {
  return (
    <TabPanel
      p={0}
      h="full"
      flex={1}
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      overflow="auto"
      {...props}
    >
      {children}
    </TabPanel>
  );
}
